<template>
    <div class="bill_template template_4" :class="{'noborder':!data.templateSettings.tableRowBorder, 'preview':preview, 'print-settings':isEditable, 'gridall':getGridClass==1, 'font_small':invoiceFieldsObj.fontSize.value=='small', 'font_large':invoiceFieldsObj.fontSize.value=='large',  'font_xl':invoiceFieldsObj.fontSize.value=='xl', 'font_xxl':invoiceFieldsObj.fontSize.value=='xxl'}" full-width v-if="dataLoaded">

        <table class="invoice_table invoice_table_top" id="body_template_1" full-width>
            <tbody>
                <template>
                    <tr>
                        <td :colspan="headers.length" left-align :style="{'background':invoiceColor}" class="noborder">
                            <div full-height class="title invoice_title pt-1 pb-1 center-align font-heavy font20">
                                <template v-if="$root.CurrentBusiness.GSTRegistrationStatus == 1 && data.transactionDetails.transactionListId == 10003">
                                    {{summary.totalTax && !hasNilRatedItems ? data.transactionDetails.invoiceTitle : summary.totalTax && hasNilRatedItems ? 'Invoice-cum-Bill of Supply' : 'Bill Of Supply'}}
                                </template>
                                <template v-else>
                                    {{data.transactionDetails.invoiceTitle}}
                                </template>
                            </div>
                        </td>
                    </tr>

                    <tr right-align v-if="!data.transactionDetails.status">
                        <td class="noborder redcolor" :colspan="headers.length">Cancelled!</td>
                    </tr>

                    <tr v-if="subtitle.data && subtitle.data.content">
                        <td class="noborder pl-2 pr-2" :colspan="headers.length">
                            <div v-html="subtitle.data.content" v-ripple :class="{'editable':isEditable}" @click="fn_editPrintField(subtitle.fieldId)"></div>
                        </td>
                    </tr>

                    <tr class="border-bottom">
                        <td class="noborder business_row" :colspan="headers.length" :class="{'pt-3':isEditable}">

                            <div class="col50_2" :class="{'hasLogo':!preview && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(invoiceFieldsObj.businessLogo.status)}" v-if="($root.CurrentBusiness.businessLogo && fn_showSection(invoiceFieldsObj.businessLogo.status)) || (header.data && header.data.content && fn_showSection(invoiceFieldsObj.header.status))">

                                <div class="business_logo" v-if="data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(invoiceFieldsObj.businessLogo.status)" :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.businessLogo.status}" @click="fn_editLogo">
                                    <img :src="$root.CurrentBusiness.businessLogo"/>
                                    <!-- <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Logo</div> -->
                                </div>

                                <div class="business_logo" :class="{'editable':isEditable}" @click="fn_editLogo" v-if="(!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo) && isEditable">
                                    <div style="text-align: center;padding: 14px 6px;font-size: 10px;">+ Logo</div>
                                    <!-- <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Logo</div> -->
                                </div>

                                <div style="clear:both;width:48px;float:left" class="pt-1" v-if="isEditable && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && logoField.fieldId">
                                    <v-switch class="hide-margins small-switch mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="logoField.showInPrintOut" @change="fn_toggleField($event, logoField.fieldId)"></v-switch>
                                </div>

                                <div class="business_details" :class="{'nopadding': (!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo || !invoiceFieldsObj.businessLogo.status) && !isEditable, 'pl-2':!preview && (data.templateSettings.businessLogo || $root.CurrentBusiness.businessLogo)}" v-if="header.data && header.data.content && fn_showSection(invoiceFieldsObj.header.status) || isEditable">
                                    <!-- <div v-html="header.data.content" v-if="header.data && header.data.content" v-ripple :class="{'editable':isEditable}" @click="fn_editBusiness"></div> -->
                                    <div v-html="header.data.content" v-ripple :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.header.status, 'pa-2':header.data && !header.data.content}" @click="fn_editPrintField(header.fieldId)"></div>
                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Business Details</div>
                                </div>

                                <!-- <div style="clear:both;width:48px" class="pt-1" v-if="isEditable && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && logoField.fieldId">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="logoField.showInPrintOut" @change="fn_toggleField($event, logoField.fieldId)"></v-switch>
                                </div> -->
                            </div>

                            <div class="col50_2">

                                <!-- <div style="padding: 1px 2px;border:1px solid;margin-bottom:2px" :style="{'borderColor':invoiceColor}" v-if="data.transactionDetails.transactionListId == 10003 && fn_showInvoiceForText()">
                                    {{fn_showInvoiceForText()}}
                                </div> -->
                                <div style="margin-bottom:2px" v-if="data.transactionDetails.transactionListId == 10003 && fn_showInvoiceForText()">
                                    {{fn_showInvoiceForText()}}
                                </div>

                                <v-layout row wrap style="margin:0">

                                    <v-flex xs4 sm4 md4>
                                        <div>{{data.transactionDetails.transactionListId == 10003 ? "Invoice" : fn_getTransactionTitle(data.transactionDetails.transactionListId)}} No</div>
                                        <div class="break_word"><strong>{{data.transactionDetails.transactionNumber}}</strong></div>

                                        <!-- <br> -->

                                        <!-- <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.gstNumber">
                                            <div>GSTIN</div>
                                            <div><strong>{{data.transactionDetails.partyDetails.gstNumber}}</strong></div>
                                        </div> -->
                                        </v-flex>

                                    <v-flex xs4 sm4 md4>
                                        <div>Date of {{data.transactionDetails.transactionListId == 10003 ? "Invoice" : fn_getTransactionTitle(data.transactionDetails.transactionListId)}}</div>
                                        <div><strong>{{data.transactionDetails.transactionDate | moment("DD-MMM-YYYY")}}</strong></div>
                                    </v-flex>

                                    <v-flex xs4 sm4 md4 pa-1 v-if="data.transactionDetails.refNumber">
                                        <div>Bill No</div>
                                        <div><strong>{{ data.transactionDetails.refNumber }}</strong></div>
                                    </v-flex>

                                    <v-flex xs4 sm4 md4 pa-1 v-if="data.transactionDetails.refDate">
                                        <div>Bill Date</div>
                                        <div><strong>{{ data.transactionDetails.refDate | moment("DD-MMM-YYYY") }}</strong></div>
                                    </v-flex>

                                    <v-flex xs4 sm4 md4 v-if="$root.CurrentBusiness.GSTRegistrationStatus == 1">
                                        <div>Place of Supply</div>
                                        <div><strong>{{fn_getPlaceOfSupply()}}</strong></div>
                                    </v-flex>

                                    <!-- <v-flex xs6 sm6 md6 mt-4 v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.gstNumber">
                                        <div>GSTIN</div>
                                        <div><strong>{{data.transactionDetails.partyDetails.gstNumber}}</strong></div>
                                    </v-flex> -->

                                    <!-- <v-flex xs2 sm2 md2 v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.gstNumber"></v-flex> -->

                                    <v-flex xs12 sm12 md12 mt-4 v-if="data.transactionDetails.paymentTerm && data.transactionDetails.paymentTerm!=-1">
                                        <div>Term of Payment</div>
                                        <div><strong>{{data.transactionDetails.paymentTerm}} Days</strong></div>
                                    </v-flex>
                                </v-layout>

                            </div>  
                        </td>
                    </tr>

                    <tr>
                        <td :colspan="headers.length" class="noborder" full-height nopadding>

                            <div full-height class="col33_3" :class="{'col50_2':!otherFields.length}">
                                
                                <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(invoiceFieldsObj.billingLabel.status)">

                                    <span :class="{'fade-opacity':!invoiceFieldsObj.billingLabel.status}" font-heavy v-html="billingLabel.data.content" v-if="billingLabel.data && billingLabel.data.content"></span>

                                    <div v-ripple :class="{'editable':isEditable && data.transactionDetails.partyDetails.partyName, 'fade-opacity':!invoiceFieldsObj.billingLabel.status}" @click="fn_editParty">

                                        <div v-if="data.transactionDetails.partyDetails.partyName">
                                            <div>{{data.transactionDetails.partyDetails.partyName}}</div>
                                            <div v-if="billingAddress">{{billingAddress}}</div>
                                            <div v-if="partyPhoneBilling || data.transactionDetails.partyDetails.primaryMobileNumber">Ph: {{partyPhoneBilling ? partyPhoneBilling : data.transactionDetails.partyDetails.primaryMobileNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails.email">{{'Email: ' + data.transactionDetails.partyDetails.email}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.gstNumber">GSTIN: {{data.transactionDetails.partyDetails.gstNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.msmeUdyamNumber">MSME/Udyam No: {{data.transactionDetails.partyDetails.msmeUdyamNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.panNumber">PAN No: {{data.transactionDetails.partyDetails.panNumber}}</div>
                                        </div>

                                        <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                        
                                    </div>

                                    <div class="pt-1" v-if="isEditable">
                                        <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="billingLabel.showInPrintOut" @change="fn_toggleField($event, billingLabel.fieldId)"></v-switch>
                                    </div>

                                </template>

                                <div v-if="data.transactionDetails.transactionListId == 20005 && !data.transactionDetails.partyDetails.partyName">
                                    Supplier not applicable
                                </div>

                            </div>

                            <div full-height class="col33_3" :class="{'col50_2 noborder':!otherFields.length}">

                                <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(invoiceFieldsObj.shippingLabel.status)">

                                    <span :class="{'fade-opacity':!invoiceFieldsObj.shippingLabel.status}" font-heavy v-html="shippingLabel.data.content" v-if="shippingLabel.data && shippingLabel.data.content"></span>

                                    <div v-ripple :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.shippingLabel.status}" @click="fn_editParty('expense')">

                                        <div v-if="data.transactionDetails.partyDetails.partyName">
                                            <div>{{data.transactionDetails.partyDetails.partyName}}</div>
                                            <div v-if="shippingAddress">{{shippingAddress}}</div>
                                            <div v-if="partyPhoneShipping || data.transactionDetails.partyDetails.primaryMobileNumber">Ph: {{partyPhoneShipping ? partyPhoneShipping : data.transactionDetails.partyDetails.primaryMobileNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails.email">{{'Email: ' + data.transactionDetails.partyDetails.email}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.panNumber">PAN No: {{data.transactionDetails.partyDetails.panNumber}}</div>
                                        </div>

                                        <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                    </div>

                                    <div class="pt-1" v-if="isEditable">
                                        <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17" nopadding v-model="shippingLabel.showInPrintOut" @change="fn_toggleField($event, shippingLabel.fieldId)"></v-switch>
                                        <!-- <div>Show in Print/Pdf</div> -->
                                    </div>
                                </template>

                            </div>


                            <div full-height class="col33_3 nopadding pt-1" v-if="otherFields.length">
                                <template v-for="(field, fieldIndex) in otherFields">
                                    <div v-if="field.fieldValue" :key="fieldIndex" class="bottomborder pl-1">
                                        <!-- <template v-if="field.fieldValue"> -->
                                            <div v-if="field.fieldType == 'print' && field.additionalData.content" v-html="field.additionalData.content"></div>
                                            <div v-if="field.fieldType != 'print'">{{field.name}}{{field.fieldValue ? ':' : ''}}
                                                <span v-if="field.fieldType == 'dateTime'">{{field.fieldValue | moment("DD-MMM-YYYY")}}</span>
                                                <span v-else>{{field.fieldValue}}</span>
                                            </div>  
                                        <!-- </template> -->
                                        
                                    </div>
                                </template>
                            </div>

                        </td>
                    </tr>

                    <tr v-if="data.transactionDetails.expenseCategoryName">
                        <td class="noborder pl-2 pr-2" :colspan="headers.length">
                            <div><strong>Expense For</strong>: {{data.transactionDetails.expenseCategoryName}}</div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>

        <div style="display: flex;justify-content: right;" @click="fn_openToggleOptions(description, itemDescription, brand, category, company)" right-align v-if="isEditable && filteredItems && filteredItems.length"> <div style="background: #f0f0f0;width: fit-content;padding: 0px 6px;"><v-icon>edit</v-icon> Item Details</div>	</div>
        <table class="invoice_table invoice_table_mid" full-width>
            <thead>
                <tr class="items_header noborder" :style="{'background':invoiceColor, 'color':invoiceColor=='#4b77be' ? '#ffffff' : '#000000'}">
                    <template v-for="(header, index) in headers">
                        <td v-if="showHideHeader(header.id)" class="noborder" :key="index" :class="{'right-align':  header.name!='#' && header.name!='Description' && header.name!='HSN/SAC'}" :style="{'minWidth':header.name=='Description' ? '50%' : header.name=='Mfg Date' || header.name=='Exp Date' ? '80px' : 'auto'}">
                            <strong>{{header.name}}</strong>
                        </td>
                    </template>
                </tr>
            </thead>

            <tbody>

                <template v-if="filteredItems && filteredItems.length">
                    <template v-for="(item, itemIndex) in filteredItems" class="noborder">
                        <tr v-if="isRef" class="noborder border-bottom items-row" :key="itemIndex"><td class="noborder" :colspan="headers.length"><strong>{{item.baseTransactionNumber}}</strong></td></tr>

                        <tr @click="fn_openToggleOptions(description, itemDescription, brand, category, company)" v-for="(row, index) in item.items" :key="item.baseTransactionNumber + index" class="noborder border-bottom items_row">
                            <td>{{index+1}}</td>
                            
                            <td style="width: 50%;">
                                {{row.itemName}}
                                <!-- <template v-if="row.itemDescription">
                                    <br>
                                    <span class="description_row"><em>Desc: {{ row.itemDescription }}</em></span>
                                </template>
                                <template v-if="row.description">
                                    <br>
                                    <span class="description_row"><em>Note: {{ row.description }}</em></span>
                                </template>							 -->
                                <template v-if="row.productCode && fn_showSection(invoiceFieldsObj.tableColProductCode.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColProductCode.status}"><em>{{ row.productCode }}</em></span>
                                </template>
                                <template v-if="row.itemDescription && fn_showSection(invoiceFieldsObj.tableColItemDescription.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColItemDescription.status}"><em>{{ row.itemDescription }}</em></span>
                                </template>
                                <template v-if="row.description && fn_showSection(invoiceFieldsObj.tableColItemNote.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColItemNote.status}"><em>{{ row.description }}</em></span>
                                </template>
                                <template v-if="row.brand && fn_showSection(invoiceFieldsObj.tableColBrand.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColBrand.status}"><em>{{ row.brand }}</em></span>
                                </template>
                                <template v-if="row.company && fn_showSection(invoiceFieldsObj.tableColCompany.status)">
                                    <!-- <br> -->
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColCompany.status}">/<em>{{ row.company }}</em></span>
                                </template>
                                <template v-if="row.category && fn_showSection(invoiceFieldsObj.tableColCategory.status)">
                                    <!-- <br> -->
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColCategory.status}">/<em>{{ row.category }}</em></span>
                                </template>

                                <br v-if="row.field1 || row.field2 || row.field3 || row.field4">
                                <template v-if="row.field1 && row.field1.length">
                                    <span>Serial Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field1" :key="index">{{ field }} <span v-if="row.field1.length - 1 != index">, </span></span>
                                    <br>
                                </template>

                                <template v-if="row.field2 && row.field2.length">
                                    <span>IMEI Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field2" :key="index">{{ field }} <span v-if="row.field2.length - 1 != index">, </span></span>
                                    <br>
                                </template>

                                <template v-if="row.field3 && row.field3.length">
                                    <span>Part Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field3" :key="index">{{ field }} <span v-if="row.field3.length - 1 != index">, </span></span>
                                    <br>
                                </template>

                                <template v-if="row.field4 && row.field4.length">
                                    <span>Model Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field4" :key="index">{{ field }} <span v-if="row.field4.length - 1 != index">, </span></span>
                                </template>
                            </td>

                            <td v-if="isHsn">{{row.hsn ? row.hsn : '-'}}</td>
                            <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColQty.status}" v-if="fn_showSection(invoiceFieldsObj.tableColQty.status)">{{row.qty}}</td>

                            <td right-align v-if="row.batchNumber != undefined">{{row.batchNumber}}</td>
                            <td right-align v-if="row.batchSize != undefined">{{row.batchSize}}</td>
                            <td right-align v-if="row.mfgDate != undefined">{{row.mfgDate}}</td>
                            <td right-align v-if="row.expDate != undefined">{{row.expDate}}</td>
                            <td right-align v-if="row.purchaseRate != undefined">{{row.purchaseRate}}</td>
                            
                            <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColUnit.status}" v-if="fn_showSection(invoiceFieldsObj.tableColUnit.status)">{{row.unit}}</td>
                            <td right-align :class="{'fade-opacity':invoiceFieldsObj.tableColMrp && !invoiceFieldsObj.tableColMrp.status}" v-if="invoiceFieldsObj.tableColMrp && fn_showSection(invoiceFieldsObj.tableColMrp.status) && isMrp">{{row.mrp ? $root.fixed2Decimal(row.mrp) : ''}}</td>
                            <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColRate.status}" v-if="fn_showSection(invoiceFieldsObj.tableColRate.status)">{{row.rate ? $root.fixed2Decimal(row.rate) : ''}}</td>

                            <template v-for="(header, headerIndex) in headers">
                                <td right-align :key="headerIndex" v-if="header.addOnsId">
                                    <template v-for="(addOn, addOnIndex) in row.addOns">
                                        <span v-if="header.addOnsId == addOn.addOnsId" :key="addOnIndex">
                                            {{addOn.value ? $root.fixed2Decimal(addOn.value) : ''}}
                                            <span v-if="addOn.percentage && addOn.value"><br>{{'@' + addOn.percentage + '%'}}</span>
                                        </span>
                                        <span v-else :key="addOnIndex">-</span>    
                                    </template>
                                </td>
                            </template>
                            
                            <td right-align v-if="summary.totalTax">{{row.taxableValue ? $root.fixed2Decimal(row.taxableValue) : ''}}</td>
                            
                            <template v-for="(header, headerIndex) in headers">
                                <td right-align :key="headerIndex" v-if="header.gstAddOnsId">
                                    <template v-for="(addOn, addOnIndex) in row.gstAddOns">
                                        <span :key="addOnIndex" v-if="header.gstAddOnsId == addOn.addOnsId">
                                            {{addOn.value ? $root.fixed2Decimal(addOn.value) : ''}}
                                            <span v-if="addOn.percentage && addOn.value"><br>{{'@' + addOn.percentage + '%'}}</span>
                                        </span>    
                                    </template>
                                </td>    
                            </template>
                            
                            <td right-align>{{row.rate ? $root.fixed2Decimal(row.total) : 'Free'}}</td>
                        </tr>
                    </template> 
                </template>
                
                <template v-if="filteredItems && !filteredItems.length">
                    <tr class="noborder border-bottom items-row">
                        <td>1</td>
                        <td style="width: 80%"><span class="description_row">{{data.transactionDetails.description}}</span></td>
                        <td class="right-align">{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</td>
                    </tr>
                </template>
                    
                <tr class="items_footer noborder border-bottom" v-if="filteredItems && filteredItems.length" :style="{'background':invoiceColor}">
                    <td :colspan="isHsn ? 3 : 2"><strong>Total (&#8377;)</strong></td>

                    <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColQty.status}" v-if="fn_showSection(invoiceFieldsObj.tableColQty.status)">
						<strong>{{qtyTotal ? qtyTotal : ''}}</strong>
					</td>

                    <!-- <td :colspan="headers.length - addOnsTotal.length - gstAddOnsTotal.length - (isMrp ? 5 : 6) - (isHsn ? 1 : 0)"></td> -->
                    <td :colspan="fn_footerColspanSize()"></td>

                    <template v-for="(header, headerIndex) in headers">
                        <td right-align :key="headerIndex" v-if="header.addOnsId">
                            <template v-for="(item, index) in addOnsTotal">
                                <span :key="index" v-if="header.addOnsId == item.addOnsId">
                                    <strong>{{$root.numberToLocale(item.value)}}</strong>
                                </span>    
                            </template>
                        </td>    
                    </template>
                    
                    <td right-align v-if="summary.totalTax"><strong>{{$root.numberToLocale(data.transactionDetails.taxableValue)}}</strong></td>
                    
                    <template v-for="(header, headerIndex) in headers">
                        <td right-align :key="headerIndex" v-if="header.gstAddOnsId">
                            <template v-for="(item, index) in gstAddOnsTotal">
                                <span :key="index" v-if="header.gstAddOnsId == item.gstAddOnsId">
                                    <strong>{{$root.numberToLocale(item.value)}}</strong>
                                </span>    
                            </template>
                        </td>    
                    </template>
                    

                    <td right-align><strong>{{$root.numberToLocale(netTotal)}}</strong></td>
                </tr>

                <!-- Footer in case of direct amount invoice (no items) -->
                <tr class="items_footer noborder border-bottom" v-else :style="{'background':invoiceColor}">
                    <td colspan="2"><strong>Total</strong></td>
                    <td class="right-align"><strong>{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</strong></td>
                </tr>

            </tbody>

        </table>

        <table class="invoice_table invoice_table_bottom bottom-table" full-width>
            <tbody>
                <tr class="border-bottom" :class="{'border-top':!filteredItems.length}" v-if="isEditable || invoiceFieldsObj.amountInWords.status || invoiceFieldsObj.taxAndTotal.status">
                    <td :colspan="headers.length" class="single-column-double-section noborder">
                        <div full-height class="left-column">
                            <div v-if="fn_showSection(invoiceFieldsObj.amountInWords.status)" :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.amountInWords.status}">
                                <div><strong>Total amount (in words)</strong></div>
                                <div>{{convertNumberToWords(data.transactionDetails.roundedAmount)}} Only.</div>
                            </div>

                            <div v-if="data.transactionDetails.notes" class="pt-1 topborder">
                                <strong>Note</strong><br>
                                {{data.transactionDetails.notes}}
                            </div>

                        </div>

                        <div full-height class="right-column" right-align>
                            <div v-if="fn_showSection(invoiceFieldsObj.taxAndTotal.status)" :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.taxAndTotal.status}">
                                <div class="table_div">
                                    <template v-if="showTaxLabels()">
                                        <div class="table_div_row">
                                            <div class="table_div_col">Taxable Amount (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>{{$root.numberToLocale(data.transactionDetails.taxableValue)}}</strong>
                                            </div>
                                        </div>
                                        <div class="table_div_row">
                                            <div class="table_div_col">Total Tax {{data.transactionDetails.isRcmApplicable ? '(RCM)' : ''}} (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>{{$root.numberToLocale(summary.totalTax)}}</strong>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="table_div_row" v-if="data.transactionDetails.transactionTds && data.transactionDetails.transactionTds.length">
                                        <div class="table_div_col">
                                            {{data.transactionDetails.transactionTds[0].tdsAccountName}} (&#8377;):
                                        </div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>-{{$root.numberToLocale(data.transactionDetails.transactionTds[0].amount)}}</strong>
                                        </div>
                                    </div>

                                    <div class="table_div_row" v-for="(addOn, addOnIndex) in data.transactionDetails.transactionAddOns" :key="addOnIndex">
                                        <div class="table_div_col">{{addOn.name}} {{addOn.isGst == 1 && data.transactionDetails.isRcmApplicable ? '(RCM)' : ''}} (&#8377;):</div>
                                        <div class="table_div_col pr-1" right-align>
                                            {{addOn.nature == 1 ? '-' : ''}}<strong>{{$root.numberToLocale(addOn.value)}}</strong>
                                        </div>
                                    </div>
                                    <div class="table_div_row" v-if="roundOffAmount">
                                        <div class="table_div_col">Round Off (&#8377;):</div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>{{$root.numberToLocale(roundOffAmount)}}</strong>
                                        </div>
                                    </div>
                                    <div class="table_div_row">
                                        <div class="table_div_col"><strong>Total Amount (&#8377;):</strong></div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</strong>
                                        </div>
                                    </div>

                                    <template v-if="data.transactionDetails.paymentAccounts && data.transactionDetails.paymentAccounts.length">

                                        <div class="table_div_row" v-for="(item, index) in data.transactionDetails.paymentAccounts" :key="index">
                                            <div class="table_div_col">Paid by {{item.accountLedgerName == 'Cash A/c' ? 'Cash' : item.accountLedgerName}} (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>{{$root.numberToLocale(item.amount)}}</strong>
                                            </div>
                                        </div>

                                    </template>

                                    <template v-else>
                                        <div class="table_div_row" v-if="showBalanceLabel()">
                                            <div class="table_div_col">Received (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>0</strong>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="table_div_row" v-if="showBalanceLabel() && fn_showSection(invoiceFieldsObj.tableColSummaryBalance.status)" :class="{'fade-opacity':!invoiceFieldsObj.tableColSummaryBalance.status}">
                                        <div class="table_div_col">Balance (&#8377;):</div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>{{data.transactionDetails.onCredit ? $root.numberToLocale(data.transactionDetails.onCredit) : '0'}}</strong>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </td>
                </tr>

                <template v-if="hsnSummary.items.length && hasHsnSummary && fn_showSection(invoiceFieldsObj.hsnSummary.status)">
                    <tr :style="{'background':invoiceColor}" class="border-bottom noborder hsn-summary-headers" :class="{'fade-opacity':!invoiceFieldsObj.hsnSummary.status}">
                        <td font-medium center-align :colspan="headers.length - hsnSummary.headers.length -2">HSN/SAC</td>
                        <td font-medium right-align :colspan="index == 0 || index == hsnSummary.headers.length - 1 ? 2 : 1" class="hsn-dynamic-headers" v-for="(hsnHeader, index) in hsnSummary.headers" :key="hsnHeader.name">{{ hsnHeader.name }}</td>
                    </tr>

                    <tr class="border-bottom noborder" v-for="(summary, summaryIndex) in hsnSummary.items" :key="summaryIndex" :class="{'fade-opacity':!invoiceFieldsObj.hsnSummary.status}">
                        <td :colspan="headers.length - hsnSummary.headers.length - 2">{{summary.hsn}}</td>
                        <td right-align :colspan="2">{{$root.fixed2Decimal(summary.taxableValue)}}</td>
                        <td right-align v-for="(gst, gstIndex) in summary.gstAddOns" :key="gstIndex"><div v-if="gst.percentage">@{{gst.percentage}}%&nbsp;&nbsp;{{$root.fixed2Decimal(gst.value)}}</div></td>
                        <td right-align :colspan="2">{{$root.fixed2Decimal(summary.totalTax)}}</td>
                    </tr>

                    <tr :style="{'background':invoiceColor}" class="border-bottom noborder" :class="{'fade-opacity':!invoiceFieldsObj.hsnSummary.status}">
                        <td right-align font-medium :colspan="headers.length - hsnSummary.headers.length -2">Total</td>
                        <td right-align font-medium :colspan="footerIndex == 0 || footerIndex == hsnSummary.footers.length - 1 ? 2 : 1" v-for="(footerValue, footerIndex) in hsnSummary.footers" :key="footerIndex">{{$root.numberToLocale(footerValue)}}</td>
                    </tr>

                    <!-- <tr v-if="isEditable">
                        <td style="border-color: #fff !important;" :colspan="headers.length">
                            <v-switch class="hide-margins small-switch float-right mr-1 mt-1" hide-details :height="17" nopadding v-model="invoiceFieldsObj.hsnSummary.status" @change="fn_copyFieldObj(invoiceFieldsObj.hsnSummary)"></v-switch>
                        </td>
                    </tr> -->

                </template>

                <tr v-if="hasFooterSection">
                    <td :colspan="headers.length" class="noborder" :class="{'pt-5':isEditable}">

                        <footer-component v-if="dataLoaded"></footer-component>

                        <!-- <div class="bottom-row-container">
                            <div full-height class="bottom-left-column">

                                <template v-if="fn_showSection(terms.showInPrintOut) && terms.fieldId">
                                    
                                    <div :class="{'terms-section':printNote.data && printNote.data.content && printNote.showInPrintOut && terms.data && terms.data.content}">

                                        <div v-ripple class="pt-1 pb-1" :class="{'fade-opacity':!terms.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(terms.fieldId)">
                                            <div v-html="terms.data.content" v-if="terms.data && terms.data.content"></div>

                                            <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Terms</div>
                                        </div>  

                                        <div v-if="isEditable && terms.data && terms.data.content">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="terms.showInPrintOut" @change="fn_toggleField($event, terms.fieldId)"></v-switch>
                                        </div>

                                    </div>

                                </template>

                                <template v-if="fn_showSection(printNote.showInPrintOut) && printNote.fieldId">

                                    <div class="printnote-section" :class="{'mt-12 mb-8':isEditable, 'borderwhite':(!$root.CurrentBusiness.UpiID || !upiDetailField.showInPrintOut) && (!bankDetails.data || !bankDetails.data.content || !bankDetails.showInPrintOut)}">
                                        <div v-ripple class="pt-1 pb-1" :class="{'fade-opacity':!printNote.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(printNote.fieldId)">
                                            <div v-html="printNote.data.content" v-if="printNote.data && printNote.data.content"></div>

                                            <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Print Note</div>
                                        </div>  

                                        <div v-if="isEditable && printNote.data && printNote.data.content">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="printNote.showInPrintOut" @change="fn_toggleField($event, printNote.fieldId)"></v-switch>
                                        </div>
                                    </div>

                                </template>

                                <template v-if="(showBankDetails() && $root.CurrentBusiness.UpiID && fn_showSection(upiDetailField.showInPrintOut)) || (showBankDetails() && isEditable)">
                                    
                                    <div class="upi-details-section" :class="{'mt-1':isEditable}">
                                        <div class="pb-1" :class="{'fade-opacity':!upiDetailField.showInPrintOut, 'editable':isEditable}" nopadding @click="fn_editUpiDetails">

                                            <template v-if="$root.CurrentBusiness.UpiID">
                                                <div class="qr-code-container">
                                                    <img :src="paymentQrCodeUrl"/>
                                                </div>
                                                
                                                <div class="qr-code-details">
                                                    <p>Scan and Pay using UPI</p>
                                                    <p>UPI ID: {{$root.CurrentBusiness.UpiID}}</p>
                                                    <p>UPI Name: {{$root.CurrentBusiness.UPIName}}</p>
                                                </div>
                                            </template>

                                            <template v-if="isEditable">
                                                <div class="editabled-field-label" v-if="$root.CurrentBusiness.UpiID"><v-icon>edit</v-icon> UPI Details</div>
                                                <div class="editabled-field-label" v-else><v-icon>add</v-icon> UPI Details</div>    
                                            </template>

                                        </div>

                                        <div class="left-align" v-if="isEditable && $root.CurrentBusiness.UpiID && upiDetailField.fieldId">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="upiDetailField.showInPrintOut" @change="fn_toggleField($event, upiDetailField.fieldId)"></v-switch>
                                        </div>
                                    </div>

                                </template>

                                <template v-if="(showBankDetails() && bankDetails.data && bankDetails.data.content && fn_showSection(bankDetails.showInPrintOut)) || (showBankDetails() && isEditable)">

                                    <div class="bank-details-section" :class="{'mt-1':isEditable}">
                                        <div class="pb-1" v-ripple :class="{'fade-opacity':!bankDetails.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(bankDetails.fieldId)">

                                            <template v-if="isEditable">
                                                <div v-if="bankDetails.data && bankDetails.data.content" class="editabled-field-label"><v-icon>edit</v-icon> Bank Details</div>
                                                <div v-else class="editabled-field-label"><v-icon>add</v-icon> Bank Details</div>   
                                            </template>
                                            
                                            
                                            <template v-if="bankDetails.data && bankDetails.data.content">
                                                <div v-html="bankDetails.data.content"></div>
                                            </template>

                                        </div>

                                        <div v-if="isEditable && bankDetails.data && bankDetails.data.content">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="bankDetails.showInPrintOut" @change="fn_toggleField($event, bankDetails.fieldId)"></v-switch>
                                        </div>
                                    </div>
                                    

                                </template>
                                

                            </div>


                            <div nopadding class="bottom-right-column">

                                <template v-if="fn_showSection(signatory.showInPrintOut)">

                                    <div class="pt-1 pb-1" :class="{'fade-opacity':!signatory.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(signatory.fieldId, 'signature')">

                                        <div right-align class="signatory" v-ripple v-html="signatory.data.content" v-if="signatory.data && signatory.data.content"></div>

                                        <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Signature</div>

                                    </div>

                                    <div class="pb-3" v-if="isEditable && signatory.data && signatory.data.content">
                                        <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="signatory.showInPrintOut" @change="fn_toggleField($event, signatory.fieldId)"></v-switch>
                                    </div>  

                                </template>

                                <template v-if="fn_showSection(brandLogo.showInPrintOut)">
                                    <div :class="{'row-app-logo':isEditable}">
                                        <div class="invoice-footer mt-4" :class="{'fade-opacity': !brandLogo.showInPrintOut}">
                                            <div class="branding grey-back" style="position:relative">
                                                <v-btn @click="fn_toggleBrandLogo(false, 1)" v-if="!isEditable" style="border-radius: 4px;height: 18px;padding: 0px 8px 0px 6px;background: #fff;position: absolute;top: -19px;right: 0px;border: 1px #ddd solid;min-width: auto !important;" capitalize class="ma-0 mt-1 remove-logo-btn"><v-icon style="font-size: 10px !important;" class="mr-1" color="primary">close</v-icon> Remove</v-btn>
                                                <a href="https://bizopsapp.com/" target="_blank">
                                                    <div class="branding-box">
                                                        <div class="dark-opacity-color powered">Powered By</div>
                                                        <div class="title primary-color">
                                                            <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                                                        </div>
                                                    </div>
                                                </a>

                                            </div>
                                        </div>
                                        

                                        <div class="pb-2 pt-2" v-if="isEditable">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17" v-model="brandLogo.showInPrintOut"  nopadding @change="fn_toggleBrandLogo"></v-switch>
                                        </div>
                                    </div>
                                </template>

                                
                            </div>
                        </div> -->


                    </td>
                </tr>

                <tr v-if="fn_showSection(invoiceFieldsObj.bottomNote.status)">
                    <td center-align class="noborder borderwhite" :colspan="headers.length">
                        <div class="pa-1" v-ripple style="height:auto;" :class="{'fade-opacity':!invoiceFieldsObj.bottomNote.status, 'editable mt-3':isEditable}">
                            <div v-html="invoiceFieldsObj.bottomNote.value" v-if="invoiceFieldsObj.bottomNote.value"></div>
                            <div v-else>This is a Computer Generated Invoice.</div>
                        </div>
                    </td>
                </tr>

            </tbody>

            <!-- <tfoot> -->
                <!-- <tr class="noborder" :class="{'row-app-logo':isEditable}" v-if="fn_showSection(brandLogo.showInPrintOut)">
                    <td right-align class="noborder" :colspan="headers.length">
                        <div class="invoice-footer" :class="{'fade-opacity': !brandLogo.showInPrintOut}">
                            <div class="branding grey-back" style="position: relative;">
                                <v-btn @click="fn_toggleBrandLogo(false, 1)" v-if="!isEditable" style="font-size: 8px !important;border-radius: 4px;height: 20px;padding: 0px 8px 0px 6px;background: #fff;position: absolute;top: -20px;right: 0px;border: 1px #ddd solid;min-width: auto !important;" color="primary" capitalize text class="ma-0 mt-1 remove-logo-btn"><v-icon style="font-size: 10px !important;" class="mr-1" color="primary">close</v-icon> Remove</v-btn>
                                <a href="https://bizopsapp.com/" target="_blank">
                                    <div class="branding-box">
                                        <div class="dark-opacity-color powered">Powered By</div>
                                        <div class="font16 title primary-color">
                                            <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="pb-2 pt-2" v-if="isEditable">
                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17" v-model="brandLogo.showInPrintOut"  nopadding @change="fn_toggleBrandLogo"></v-switch>
                        </div>
                    </td>
                </tr> -->
            <!-- </tfoot> -->

        </table>
    </div>
</template>

<script>
import {invoiceTemplateMixin} from '../invoice_template_mixin'
import footerComponent from '../components/invoice_footer_section'

    export default{
        mixins: [invoiceTemplateMixin],
        components:{
            'footer-component':footerComponent
        }
        // props:['data'],
        // data(){
        //  return{
        //      billingAddress:null,
        //      partyPhoneBilling:null,
        //      partyPhoneShipping:null,
        //      shippingAddress:null,
        //      otherFields:[],
        //      // showOtherFields:false,
        //      headers:[],
        //      items:[],
        //      summary:{"totalTax":0},
        //      addOnsTotal:[],
        //      gstAddOnsTotal:[],
        //      netTotal:0,
        //      isHsn:false,
        //      isMrp:false,
        //      header:null,
        //      terms:null,
        //      bankDetails:null,
        //      signatory:null,
        //      subtitle:null,
        //      billingLabel:null,
        //      shippingLabel:null,
        //      printDetails:null,
        //      isRef:false
        //  }
        // },
        // methods:{
        //  fn_init: function(){
        //      this.fn_processAddress();
        //      this.fn_processFields();
        //      this.fn_processTransactionItems();
        //      this.fn_processSummary();
        //      this.fn_processSections();
        //      this.fn_processKeywords();
        //      this.fn_processPrintDetails();
        //  },
        //  fn_processAddress: function(){
        //      let addresses = this.data.transactionDetails.partyDetails.address;
        //      for(let i=0; i<addresses.length; i++){
        //          if(addresses[i].groupTypeId==101 && !this.billingAddress){
        //              let addressLine = addresses[i].addressLine ? addresses[i].addressLine + ', ' : '';
        //              let city = addresses[i].city ? addresses[i].city + ', ' : '';
        //              let state = addresses[i].state ? addresses[i].state + ', ' : '';
        //              let country = addresses[i].country ? addresses[i].country : '';
        //              let pincode = addresses[i].pincode ? ', ' + addresses[i].pincode : '';
        //              this.billingAddress = addressLine + city + state + country + pincode;
        //              this.partyPhoneBilling = addresses[i].phone ? addresses[i].phone : null;
        //          }
        //          else if(addresses[i].groupTypeId==102 && !this.shippingAddress){
        //              let addressLine = addresses[i].addressLine ? addresses[i].addressLine + ', ' : '';
        //              let city = addresses[i].city ? addresses[i].city + ', ' : '';
        //              let state = addresses[i].state ? addresses[i].state + ', ' : '';
        //              let country = addresses[i].country ? addresses[i].country : '';
        //              let pincode = addresses[i].pincode ? ', ' + addresses[i].pincode : '';
        //              this.shippingAddress = addressLine + city + state + country + pincode;
        //              this.partyPhoneShipping = addresses[i].phone ? addresses[i].phone : null;
        //          }
        //          else if(addresses[i].groupTypeId==103 && !this.billingAddress && !this.shippingAddress){
        //              let addressLine = addresses[i].addressLine ? addresses[i].addressLine + ', ' : '';
        //              let city = addresses[i].city ? addresses[i].city + ', ' : '';
        //              let state = addresses[i].state ? addresses[i].state + ', ' : '';
        //              let country = addresses[i].country ? addresses[i].country : '';
        //              let pincode = addresses[i].pincode ? ', ' + addresses[i].pincode : '';
        //              this.billingAddress = addressLine + city + state + country + pincode;
        //              this.shippingAddress = addressLine + city + state + country + pincode;
        //              this.partyPhoneBilling = addresses[i].phone ? addresses[i].phone : null;
        //              this.partyPhoneShipping = addresses[i].phone ? addresses[i].phone : null;
        //          }
        //      }
        //  },
        //  fn_processFields: function(){
        //      this.otherFields = [];
                
        //      // inserting ewayBillFields if applicable
        //      if(this.data.transactionDetails.roundedAmount>=50000){
        //          let ewayBillFields = JSON.parse(JSON.stringify(require('../../../config.js').configs.transactionFields.ewayBillDetailFields))
        //          for(let k=0; k<ewayBillFields.length; k++){
        //              this.data.templateSettings.printTemplateFields.push(ewayBillFields[k]);
        //          }
        //      }

        //      // matching applicable fields
        //      for(let i=0; i<this.data.templateSettings.printTemplateFields.length; i++){

        //          let printField = this.data.templateSettings.printTemplateFields[i];
        //          printField.fieldValue = null;

        //          if(printField.fieldType=='print'){
        //              printField.additionalData = JSON.parse(printField.additionalData);
        //          }

        //          for(let j=0; j<this.data.transactionDetails.otherDetails.transactionFields.length; j++){

        //              let transactionField = this.data.transactionDetails.otherDetails.transactionFields[j];
        //              if(printField.fieldId == transactionField.fieldId){
        //                  printField.fieldValue = transactionField.fieldValue;
        //                  this.otherFields.push(printField);
        //                  break;
        //              }   
        //          }
        //      }

        //      console.log(JSON.stringify(this.otherFields));
        //  },

        //  // fn_processFields: function(){
        //  //  this.otherFields = [];
        //  //  for(let i=0; i<this.data.transactionDetails.otherDetails.transactionSections.length; i++){
        //  //      let sectionObj = {};
        //  //      sectionObj.sectionId = this.data.transactionDetails.otherDetails.transactionSections[i].sectionId;
        //  //      sectionObj.sectionName = this.data.transactionDetails.otherDetails.transactionSections[i].sectionName;
        //  //      sectionObj.fields = [];
        //  //      for(let k=0; k<this.data.transactionDetails.otherDetails.transactionFields.length; k++){

        //  //          for(let j=0; j<this.data.templateSettings.printTemplateFields.length; j++){

        //  //              let field = this.data.transactionDetails.otherDetails.transactionFields[k];
        //  //              if(sectionObj.sectionId == field.sectionId && this.data.templateSettings.printTemplateFields[j].printTemplateFieldId == field.fieldId){
        //  //                  let fieldObj = {};
        //  //                  fieldObj.fieldName = field.fieldName;
        //  //                  fieldObj.fieldValue = field.fieldValue;
        //  //                  sectionObj.fields.push(fieldObj);
        //  //                  break;
        //  //              }

        //  //          }
                        
        //  //      }
                    

        //  //      this.otherFields.push(sectionObj);
        //  //  }

        //  //  console.log(JSON.stringify(this.otherFields));
        //  // },
        //  fn_processTransactionItems: function(){
        //      this.headers = [{"name":"S.No"}, {"name":"Description"}, {"name":"HSN/SAC"}, {"name":"Qty"}, {"name":"MRP"}, {"name":"Rate"}]
        //      this.items = [];
        //      this.isHsn = false;
        //      this.isMrp = false;
        //      for(let i=0; i<this.data.transactionDetails.transactionItems.length; i++){
        //          let item = this.data.transactionDetails.transactionItems[i].cols;
        //          let itemObj = {};
        //          itemObj.itemName = item.itemName;
        //          itemObj.hsn = item.hsn;
        //          itemObj.qty = item.qty;
        //          itemObj.mrp = item.mrp;
        //          itemObj.rate = item.rate;
        //          itemObj.addOns = [];
        //          itemObj.gstAddOns = [];
        //          itemObj.description = this.data.transactionDetails.transactionItems[i].description;
        //          itemObj.baseTransactionNumber = this.data.transactionDetails.transactionItems[i].baseTransactionNumber;

        //          for(let j=0; j<item.addOns.length; j++){

        //              if(!item.addOns[j].isGst){
        //                  itemObj.addOns.push(item.addOns[j]);

        //                  let headerIndex = this.headers.findIndex(header=>{
        //                      return header.addOnsId == item.addOns[j].addOnsId;
        //                  })

        //                  if(headerIndex==-1){
        //                      let obj = {};
        //                      obj.addOnsId = item.addOns[j].addOnsId;
        //                      obj.name = item.addOns[j].name;
        //                      this.headers.push(obj);
        //                  }
        //              }
        //              else{
        //                  itemObj.gstAddOns.push(item.addOns[j]);
        //              }
                        
        //          }

        //          itemObj.taxableValue = item.taxableValue;
        //          itemObj.total = item.netAmount;
                    
        //          this.items.push(itemObj);

        //          item.hsn && this.$root.CurrentBusiness.GSTRegistrationStatus == 1 ? this.isHsn = true : '';
        //          item.mrp && this.$root.TransactionSettings.mrpWiseStock ? this.isMrp = true : '';
        //      }

        //      this.headers.push({"name":"Taxable Value"});

        //      for(let l=0; l<this.items.length; l++){
        //          for(let k=0; k<this.items[l].gstAddOns.length; k++){
        //              let headerIndex = this.headers.findIndex(header=>{
        //                  return header.gstAddOnsId == this.items[l].gstAddOns[k].addOnsId;
        //              })

        //              if(headerIndex==-1){
        //                  let obj = {};
        //                  obj.gstAddOnsId = this.items[l].gstAddOns[k].addOnsId;
        //                  obj.name = this.items[l].gstAddOns[k].name;
        //                  this.headers.push(obj);
        //              }
        //          }
        //      }

        //      //removing hsn column from invoice if not mentioned in any invoice
        //      if(!this.isHsn){
        //          this.headers.splice(2, 1);
        //          for(let i=0; i<this.items.length; i++){
        //              delete this.items[i].hsn;
        //          }
        //      }

        //      //removing mrp column from invoice if not mentioned in any invoice
        //      if(!this.isMrp){
        //          this.headers.splice(3, 1);
        //          for(let i=0; i<this.items.length; i++){
        //              delete this.items[i].mrp;
        //          }
        //      }

        //      // this.headers.push({"name":"Taxable Value"});
        //      this.headers.push({"name":"Total"});

        //      console.log(JSON.stringify(this.headers));
        //      console.log(JSON.stringify(this.items));
        //  },
        //  fn_processSummary: function(){
        //      let totalTax = 0;
        //      let netTotal = 0;

        //      this.addOnsTotal = [];
        //      this.gstAddOnsTotal = [];
                
        //      for(let i=0; i<this.items.length; i++){

        //          netTotal += this.items[i].total;

        //          for(let j=0; j<this.items[i].addOns.length; j++){

        //              let addOnsIndex = this.addOnsTotal.findIndex(addOn=>{
        //                  return addOn.addOnsId == this.items[i].addOns[j].addOnsId;
        //              });

        //              if(addOnsIndex==-1){
        //                  let obj = {};
        //                  obj.addOnsId = this.items[i].addOns[j].addOnsId;
        //                  obj.name = this.items[i].addOns[j].name;
        //                  obj.value = this.items[i].addOns[j].value;
        //                  this.addOnsTotal.push(obj);
        //              }
        //              else{
        //                  this.addOnsTotal[addOnsIndex].value += this.items[i].addOns[j].value;
        //              }
        //          }

        //          for(let k=0; k<this.items[i].gstAddOns.length; k++){

        //              let addOnsIndex = this.gstAddOnsTotal.findIndex(addOn=>{
        //                  return addOn.gstAddOnsId == this.items[i].gstAddOns[k].addOnsId;
        //              });

        //              if(addOnsIndex==-1){
        //                  let obj = {};
        //                  obj.gstAddOnsId = this.items[i].gstAddOns[k].addOnsId;
        //                  obj.name = this.items[i].gstAddOns[k].name;
        //                  obj.value = this.items[i].gstAddOns[k].value;
        //                  this.gstAddOnsTotal.push(obj);
        //              }
        //              else{
        //                  this.gstAddOnsTotal[addOnsIndex].value += this.items[i].gstAddOns[k].value;
        //              }

        //              totalTax += this.items[i].gstAddOns[k].value;
        //          }
        //      }

        //      this.summary.totalTax = this.roundUpto2Decimals(totalTax);
        //      this.netTotal = this.roundUpto2Decimals(netTotal);

        //      console.log(JSON.stringify(this.addOnsTotal));
        //  },
        //  fn_processSections: function(){
        //      for(let i=0; i<this.data.templateSettings.printTemplateSections.length; i++){
        //          let additionalData = this.data.templateSettings.printTemplateSections[i].addData;
        //          if(this.data.templateSettings.printTemplateSections[i].sectionName == 'header'){
        //              this.header = JSON.parse(additionalData);
        //          }
        //          else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'terms'){
        //              this.terms = JSON.parse(additionalData);
        //          }
        //          else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'bankDetails'){
        //              this.bankDetails = JSON.parse(additionalData);
        //          }
        //          else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'signature'){
        //              this.signatory = JSON.parse(additionalData);
        //          }
        //          else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'subtitle'){
        //              this.subtitle = JSON.parse(additionalData);
        //          }
        //          else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'billingLabel'){
        //              this.billingLabel = JSON.parse(additionalData);
        //          }
        //          else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'shippingLabel'){
        //              this.shippingLabel = JSON.parse(additionalData);
        //          }
        //      }
        //  },
        //  fn_processKeywords: function(){
        //      this.fn_replaceKeywords(this.header);
        //      this.fn_replaceKeywords(this.terms);
        //      this.fn_replaceKeywords(this.bankDetails);
        //      this.fn_replaceKeywords(this.signatory);
        //      this.fn_replaceKeywords(this.subtitle);
        //  },
        //  fn_replaceKeywords: function(category){
        //      if(category && category.content){
        //          category.content = category.content.replace('{{BusinessName}}', this.$root.CurrentBusiness.BusinessName);
        //          category.content = category.content.replace('{{BusinessAddress}}', this.$root.CurrentBusiness.Address ? this.$root.CurrentBusiness.Address : '');
        //          category.content = category.content.replace('{{BusinessState}}', this.$root.CurrentBusiness.State);
        //      }
        //  },
        //  fn_processPrintDetails: function(){
        //      this.printDetails = JSON.parse(this.data.transactionDetails.partyDetails.printDetails);
        //  },
        //  convertNumberToWords: function(amount) {
        //      var words = new Array();
        //      words[0] = '';
        //      words[1] = 'One';
        //      words[2] = 'Two';
        //      words[3] = 'Three';
        //      words[4] = 'Four';
        //      words[5] = 'Five';
        //      words[6] = 'Six';
        //      words[7] = 'Seven';
        //      words[8] = 'Eight';
        //      words[9] = 'Nine';
        //      words[10] = 'Ten';
        //      words[11] = 'Eleven';
        //      words[12] = 'Twelve';
        //      words[13] = 'Thirteen';
        //      words[14] = 'Fourteen';
        //      words[15] = 'Fifteen';
        //      words[16] = 'Sixteen';
        //      words[17] = 'Seventeen';
        //      words[18] = 'Eighteen';
        //      words[19] = 'Nineteen';
        //      words[20] = 'Twenty';
        //      words[30] = 'Thirty';
        //      words[40] = 'Forty';
        //      words[50] = 'Fifty';
        //      words[60] = 'Sixty';
        //      words[70] = 'Seventy';
        //      words[80] = 'Eighty';
        //      words[90] = 'Ninety';
        //      amount = amount.toString();
        //      var atemp = amount.split(".");
        //      var number = atemp[0].split(",").join("");
        //      var n_length = number.length;
        //      var words_string = "";
        //      if (n_length <= 9) {
        //          var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        //          var received_n_array = new Array();
        //          for (var i = 0; i < n_length; i++) {
        //              received_n_array[i] = number.substr(i, 1);
        //          }
        //          for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        //              n_array[i] = received_n_array[j];
        //          }
        //          for (var i = 0, j = 1; i < 9; i++, j++) {
        //              if (i == 0 || i == 2 || i == 4 || i == 7) {
        //                  if (n_array[i] == 1) {
        //                      n_array[j] = 10 + parseInt(n_array[j]);
        //                      n_array[i] = 0;
        //                  }
        //              }
        //          }
        //          var value = "";
        //          for (var i = 0; i < 9; i++) {
        //              if (i == 0 || i == 2 || i == 4 || i == 7) {
        //                  value = n_array[i] * 10;
        //              } else {
        //                  value = n_array[i];
        //              }
        //              if (value != 0) {
        //                  words_string += words[value] + " ";
        //              }
        //              if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
        //                  words_string += "Crores ";
        //              }
        //              if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
        //                  words_string += "Lakhs ";
        //              }
        //              if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
        //                  words_string += "Thousand ";
        //              }
        //              if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
        //                  words_string += "Hundred and ";
        //              } else if (i == 6 && value != 0) {
        //                  words_string += "Hundred ";
        //              }
        //          }
        //          words_string = words_string.split("  ").join(" ");
        //      }
        //      return words_string;
        //  }
        // },
        // mounted(){
        //  this.fn_init();
        // },
        // computed:{
        //  filteredItems: function(){
        //      let transactionGroups = [];

        //      for(let i in this.items){

        //          if(this.items[i].baseTransactionNumber != this.data.transactionDetails.transactionNumber){
        //              this.isRef = true;
        //          }

        //          let itemIndex = transactionGroups.findIndex(transItem => transItem.baseTransactionNumber==this.items[i].baseTransactionNumber);

        //          if(itemIndex!=-1){
        //              transactionGroups[itemIndex].items.push(this.items[i]);
        //          }
        //          else{
        //              let obj = {};
        //              obj.baseTransactionNumber = this.items[i].baseTransactionNumber;
        //              obj.items = []
        //              obj.items.push(this.items[i]);
        //              transactionGroups.push(obj);
        //          }
        //      }

        //      console.log(JSON.stringify(transactionGroups));

        //      return transactionGroups;
        //  }
        // }
    }
</script>

<style>
    /* ---------------- Primary Header Css -----------------*/
    /*.template_4 .business_logo{
        width: 80px;
        height:80px;
        float: left;
    }

    .template_4 .business_logo img{
        width: 80px;
    }

    .template_4 .business_details{
        padding-left: 80px;
    }

    .template_4 tr.transaction_items:nth-child(even){
        background-color: #f9f9f9;
        -webkit-print-color-adjust: exact;
    }*/
    .template_4 .business_logo{
        margin-right: 4px;
        float: left;    
    }

    /* .template_4.preview .business_details{
        padding-left: 56px;
        position:relative;
    } */

    .template_4 .business_details{
        display: grid;
    }

    .template_4.print-settings .business_details{
        /* margin-left: 56px; */
        padding:0 !important;
    }

    .template_4 .hasLogo .business_details{
        /* margin-left: 56px; */
        padding:0 !important;
    }
</style>