<template>
    <div full-height>

        <div id="non-printable">

            <v-toolbar flat :height="56">
                <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn>

                <v-toolbar-title>Preview</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn id="invoice_print_btn" icon @click="fn_print" v-if="invoiceLoaded"><v-icon>print</v-icon></v-btn>

                <v-btn id="invoice_export_btn" icon @click="fn_export" :disabled="downloading" v-if="invoiceLoaded"><v-icon>picture_as_pdf</v-icon></v-btn>

                <!-- <v-btn icon @click="fn_previewSettings" v-if="invoiceLoaded"><v-icon>settings</v-icon></v-btn> -->

                <v-menu v-if="data && data.transactionDetails && data.transactionDetails.transactionListId == 10003 && invoiceLoaded">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item v-for="(item, index) in invoiceTypes" :key="index" @click="fn_invoiceForTextUpdate(item)">
                            <v-list-item-title>{{item.name}}</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon color="primary">{{item.name == invoiceForText ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <!-- <v-btn icon flat @click="fn_templateSettings" v-if="invoiceLoaded"><v-icon>settings</v-icon></v-btn>

                <v-menu v-if="data && data.transactionDetails && data.transactionDetails.transactionListId == 10003 && invoiceLoaded">
                    <v-btn slot="activator" icon><v-icon>more_vert</v-icon></v-btn>
                    <v-list nopadding>
                        <v-list-item v-for="(item, index) in invoiceTypes" :key="index" @click="fn_invoiceFor(item)">
                            <v-list-item-title>{{item}}</v-list-item-title>
                            <v-list-item-action>
                                <v-icon color="primary">{{item == invoiceFor ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-menu> -->

            </v-toolbar>

            <v-divider></v-divider>

            <!-- <div full-height class="hasToolbar grey-back" style="display:flex;justify-content:center"> -->
            <div class="data-container">

                <!-- <div style="overflow-x:scroll"> -->
                <!-- <div white-background style="transform-origin: 50% 24px 0px;flex:1;" :class="[hasItems ? data.transactionDetails.roundedAmount > 9999999 ? 'transform-preview-with-items-large' : 'transform-preview-with-items' : 'transform-preview']"> -->
                <!-- <div white-background style="transform-origin: 50% 24px 0px;flex:1;" :class="[headersColumnCount >= 10 ? 'transform-preview-with-items-xlarge' : fn_getTransformClass()]"> -->
                <!-- <v-progress-linear height="4" nomargin indeterminate color="primary" v-if="!invoiceLoaded && templateNo!=101"></v-progress-linear> -->

                <div :class="{'visibility-hidden':!invoiceLoaded && templateNo!=101}">
                    <!-- <template v-show="invoiceLoaded"> -->
                        <v-card id="invoice_card" class="ma-4" v-if="previewTemplate && data!=null" style="font-size:6px !important">
                            <v-card-text>
                                <!-- <component :is="previewTemplate" ref="ref_previewTemplate" :data="data" :preview="true" :isEditable="false" :themeColor="themeColor" :invoiceFor="invoiceForText" :headersLength="fn_headersLength" :bizOpsLogo="bizOpsLogo"></component> -->
                                <component :is="previewTemplate" ref="ref_previewTemplate" :data="data" :preview="true" :isEditable="false" :themeColor="themeColor" :headersLength="fn_headersLength" :bizOpsLogo="bizOpsLogo"></component>
                            </v-card-text>
                        </v-card>
                    <!-- </template> -->
                </div>

               <!--  <v-footer class="invoice-footer-actions" v-if="invoiceLoaded">

                    <v-layout white-background grid wrap text-xs-center>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_initPrintTemplate('sharewhatsapp')">
                            <div right-border>
                                <div style="height:26px"><img src="../../assets/icon_whatsapp.svg" alt="whatapp_icon"></img></div>
                                <div font11 class="font-weight-bold">Send</div>
                            </div>  
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_initPrintTemplate('share')">
                            <div right-border>
                                <div><v-icon>share</v-icon></div>
                                <div font11 class="font-weight-bold">Share</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_initPrintTemplate('print')">
                            <div right-border>
                                <div><v-icon>print</v-icon></div>
                                <div font11 class="font-weight-bold">Print</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_initPrintTemplate">
                            <div right-border>
                                <div><v-icon>vertical_align_bottom</v-icon></div>
                                <div font11 class="font-weight-bold">Download</div>
                            </div>
                        </v-flex>

                    </v-layout>

                    <div v-ripple class="pb-2 pt-2" center-align style="width:20%" white-background @click="fn_newInvoice">
                        <v-icon>add_circle_outline</v-icon>   
                        <div font11 class="font-weight-bold">New</div>
                    </div>
                </v-footer> -->
            </div>
        </div>

        <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px)" v-if="!previewTemplate">
          <v-col cols="6">
            <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
          </v-col>
        </v-row>
        

        <div id="printable" style="width: 100vw">
            <!-- <component :is="printTemplate" ref="ref_printTemplate" :data="data" :themeColor="themeColor" :invoiceFor="invoiceForText" :bizOpsLogo="bizOpsLogo" v-if="printTemplate && data!=null"></component> -->
            <component :is="printTemplate" ref="ref_printTemplate" :data="data" :themeColor="themeColor" :bizOpsLogo="bizOpsLogo" v-if="printTemplate && data!=null"></component>
        </div>

        <v-snackbar v-model="snackbar">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>

        <img id="bizops_logo" src="../../assets/Final_BizOpsLogo.svg" class="brand_logo logo_small" alt="bizops_logo" style="display:none">

        <!-- <dialog-permission ref="ref_dialogPermission"></dialog-permission> -->
    </div>  
</template>

<script>
const menuItem = require('../../config.js').configs.menuItem;
const transactionForms = require('../../config.js').configs.transactionForms;
// import dialogPermission from '../dialogs/dialog_permission';
// import * as htmlToImage from 'html-to-image';
// import { jsPDF } from "jspdf";
// import 'jspdf-autotable'
const html2pdf = require('html2pdf.js/dist/html2pdf.js');

    export default{
        // components:{
        //   'dialog-permission': dialogPermission,
        // },
        data(){
            return{
                data:null,
                billingAddress:null,
                partyPhoneBilling:null,
                partyPhoneShipping:null,
                shippingAddress:null,
                otherFields:[],
                headers:[],
                items:[],
                summary:{"totalTax":0},
                addOnsTotal:[],
                gstAddOnsTotal:[],
                netTotal:0,
                isHsn:false,
                isMrp:false,
                header:{data:null, fieldId:null},
                terms:{data:null, field:null},
                printNote:{data:null, field:null},
                bankDetails:{data:null, field:null},
                signatory:{data:null, field:null},
                subtitle:{data:null, field:null},
                isRef:false,
                colors:["#4b77be", "rgba(211,211,211,0.4)", "rgba(128,128,128,0.4)", "rgba(0,128,128,0.4)", "rgba(173,216,230,0.4)", "rgba(144,238,144,0.4)", "rgba(135,206,250,0.4)", "((255,182,193,0.4))"],
                themeColor:null,
                previewTemplate:null,
                printTemplate:null,
                templateNo:1,
                templates:[1, 2, 3, 4, 5],
                snackbar:false,
                invoiceTypes:[
                    {"name":"Standard"}, 
                    {"name":"Original For Recipient"},
                    {"name":"Duplicate For Transporter"},
                    {"name":"Duplicate For Supplier"},
                    {"name":"Triplicate For Supplier"}
                ],
                invoiceForText:"Standard",
                hasItems:false,
                headersColumnCount:0,
                zoomPercent:"100%",
                invoiceLoaded:false,
                bizOpsLogo:null,
                downloadFileName:null,
                downloading:false
            }       
        },
        methods:{

            fn_invoiceForTextUpdate: function(item){
                this.invoiceForText = item.name;
                let routeQuery = JSON.parse(JSON.stringify(this.$route.query));
                routeQuery.invoiceFor = item.name;
                this.$router.replace({path:this.$route.path, query:routeQuery});
            },

            fn_convertLogoToBase64: function(){
                var c = document.createElement('canvas');
                var img = document.getElementById('bizops_logo');
                c.height = img.naturalHeight;
                c.width = img.naturalWidth;
                var ctx = c.getContext('2d');

                ctx.drawImage(img, 0, 0, c.width, c.height);
                this.bizOpsLogo = c.toDataURL();
                console.log(this.bizOpsLogo);
            },

            fn_insertPageBreak: function(){
                let topRows = Array.from(document.querySelectorAll('#non-printable .invoice_table_top > tbody > tr'));
                let midRows = Array.from(document.querySelectorAll('#non-printable .invoice_table_mid > tbody > tr'));
                let bottomRows = Array.from(document.querySelectorAll('#non-printable .invoice_table_bottom > tbody > tr'));

                let rows = topRows.concat(midRows, bottomRows);

                let current_page_height = 0; //
                let max_page_height = 980; //Is the max size  of page i want. in px
                let pageCounter = 0;

                // alert((max_page_height*(pageCounter+1)));

                // let cancelLabel = '<div class="cancel_div" style="position: absolute;z-index: 0;top: '+(max_page_height*(pageCounter+1)+200)+'px;left: 38%;font-size:48px !important;color: rgba(255,0,0,0.1);">Cancelled</div>';


                // rows.forEach((tr, index)=>{
                rows.forEach((tr)=>{
                    var row_height = tr.offsetHeight;
                    current_page_height = current_page_height + row_height;

                    let headerContent = '';

                    // if(rows[index+1] && rows[index+1].classList.contains('items-row')){
                    //     headerContent = document.getElementsByClassName("items_header")[0].outerHTML;
                    // }
                    // else{
                    //     headerContent = '';
                    // }
                    if(tr.classList.contains('items-row')){
                        headerContent = document.getElementsByClassName("items_header")[0].outerHTML;
                    }
                    else{
                        headerContent = '';
                    }
                    
                    //If the sum of page rows heights are bigger thant my limit, then insert break
                    if((current_page_height + 48) > max_page_height){
                        // let cancelLabel = '';

                        // if(!this.data.transactionDetails.status){
                            // cancelLabel = '<div class="cancel_div" style="position: absolute;z-index: 0;top: '+((1080*(pageCounter+1))+68)+'px;font-size:48px !important;color: rgba(255,0,0,0.2);transform: rotate(-45deg);">Cancelled</div>';
                        // }
                        current_page_height = 0;
                        pageCounter++;
                        tr.insertAdjacentHTML('beforebegin', `<div class="html2pdf__page-break" style="height:${pageCounter + 1}px"></div>`+headerContent);

                    }

                })

                setTimeout(() => {
                    let pagebreakElements = document.getElementsByClassName("html2pdf__page-break");
                    for(let i=0; i<pagebreakElements.length;){
                        pagebreakElements[i].remove();
                    }

                    document.querySelectorAll('#non-printable .items_header').forEach((e, index)=>{
                        index !== 0 ? e.remove() : '';
                    })

                    document.querySelectorAll('#non-printable .cancel_div').forEach((e, index)=>{
                        index !== 0 ? e.remove() : '';
                    })

                    // let elements = document.getElementsByClassName("items_header");
                    // for(let j=1; j<elements.length;){
                    //     elements[j].remove();
                    // }
                }, 1000);
            },

            fn_export: function(){

                // this.fn_insertPageBreak();
                let element = null;
                if(document.getElementsByClassName('remove-logo-btn')){
                    element = document.getElementsByClassName('remove-logo-btn')[0];
                    if(element){
                        element.style.visibility = 'hidden';
                        setTimeout(() => {
                            element ? element.style.visibility = 'visible' : '';
                        }, 3000);
                    }
                }

                setTimeout(() => {
                    this.downloading = false;
                }, 2000);

                if(!this.downloading){
                    
                    this.downloading = true;

                    let transactionNumber = null;

                    if(this.data.transactionDetails){
                        transactionNumber = this.data.transactionDetails.transactionNumber ? this.data.transactionDetails.transactionNumber : '';
                    }
                    else{
                        transactionNumber = this.data.transactionNumber ? this.data.transactionNumber : '';
                    }

                    let title = null;
                    if(this.data.transactionDetails){
                        if(this.data.transactionDetails.transactionListId==10003){
                            title = "Invoice";
                        }
                        else{
                            title = this.fn_getTransactionTitle(this.data.transactionDetails.transactionListId);    
                        }
                    }
                    else{
                        title = this.data.invoiceTitle;
                    }
                    
                    this.downloadFileName = title.replace(/ /g,'') + '-' + transactionNumber.replace(/\//g,'') + '_' + this.$root.fn_getCurrentDateTimeString();

                    // let self = this;
                    let htmlContent = document.getElementsByClassName("bill_template")[0];

                    // document.getElementById("data-container").appendChild(htmlContent);

                    // var opt = {
                    //     margin:       20,
                    //     filename:     this.downloadFileName+'.pdf',
                    //     image:        { type: 'jpeg', quality: 0.98},
                    //     html2canvas:  { scale: 2 },
                    //     enableLinks: true,
                    //     jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' },
                    //     pagebreak: {before: '.html2pdf__page-break'}
                    //     // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                    //     // pagebreak:{avoid: ['table', 'tr', 'td', 'th']}
                    //     // pagebreak: { mode: 'avoid-all', before: '.html2pdf__page-break' } 

                    // };

                    var opt = {
                        margin: [15,15],
                        filename: this.downloadFileName+'.pdf',
                        image:        { type: 'jpeg', quality: 0.98 },
                        html2canvas:  { scale: 2, letterRendering: true },
                        enableLinks: true,
                        jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' },
                        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
                    };

                    html2pdf().set(opt).from(htmlContent).save();
                }
                
            },

            fn_print: function(){
                // this.fn_insertPageBreak();

                let transactionNumber = null;
                if(this.data.transactionDetails){
                    transactionNumber = this.data.transactionDetails.transactionNumber ? this.data.transactionDetails.transactionNumber : '';
                }
                else{
                    transactionNumber = this.data.transactionNumber ? this.data.transactionNumber : '';
                }

                let title = null;
                if(this.data.transactionDetails){
                    if(this.data.transactionDetails.transactionListId==10003){
                        title = "Invoice";
                    }
                    else{
                        title = this.fn_getTransactionTitle(this.data.transactionDetails.transactionListId);    
                    }
                }
                else{
                    title = this.data.invoiceTitle;
                }
                
                document.title = title.replace(/ /g,'') + '-' + transactionNumber.replace(/\//g,'') + '_' + this.$root.fn_getCurrentDateTimeString();

                setTimeout(() => {document.title = '';}, 1000);

                localStorage.getItem('direct_print') ? localStorage.removeItem('direct_print') : '';
                
                window.print(); 
            },

            fn_getTransformClass: function(){
                if(this.hasItems){
                    // if(this.$refs['ref_previewTemplate'] && this.$refs['ref_previewTemplate'].headers.length >= 10){
                    //  return 'transform-preview-with-items-xlarge';
                    // }
                    if(this.data.transactionDetails.roundedAmount > 9999999){
                        return 'transform-preview-with-items-large';
                    }
                    else{
                        return 'transform-preview';
                    }
                }
                else{
                    return 'transform-preview'
                }
            },

            fn_headersLength: function(length){
                this.headersColumnCount = length;
            },

            fn_calculateZoomPercent: function(headerWidth){
                // alert(tableWidth);
                // let bodyTableElement = document.getElementById("body_template_"+this.templateNo);
                // let percentage = ((window.outerWidth - 32) / bodyTableElement.offsetWidth) * 100;
                // let headerTableElement = document.getElementById("header_template_"+this.templateNo);
                // let elementWidth = Number(headerTableElement.style.minWidth.replace("px", ''));

                let elementWidth = Number(headerWidth.replace("px", ''));
                let percentage = ((window.outerWidth - 32) / elementWidth) * 100;
                console.log(percentage);
                this.zoomPercent = percentage + '%';

                let self = this;
                setTimeout(function(){
                    self.invoiceLoaded = true;
                },300);
            },

            fn_newInvoice: function () {

                localStorage.setItem("SetDataDrive", 1);
                if(this.$route.query.type){
                    this.$router.replace({path:'/transactions/'+this.$route.query.type+'/'+this.$route.query.title.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb()}}); 
                }
                else{
                    this.$router.replace({path:'/transactions/'+this.$route.query.title.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb()}});
                }
                
            },

            fn_changeColor: function(color){                
                this.printTemplate = null;
                this.themeColor = color;
            },

            fn_templateSettings: function(){
                // let toolbarTitle = this.$route.query.title.toLowerCase().replace(/ /g,'');
                this.$router.push({path:"/invoice/preview/settings", query:{"title": this.$route.query.title, "listId":this.$route.query.listId, "tId":this.$route.query.tId, "template_no":this.$route.query.template_no, "printtype":this.$route.query.printtype}});
            },

            fn_bindInvoiceTemplate: function(){
                this.invoiceLoaded = false;
                this.previewTemplate = null;
                this.printTemplate = null;
                console.log(this.previewTemplate);
                this.fn_convertLogoToBase64();
                this.fn_getInvoiceData();
            },

            fn_getInvoiceData: function(name){
                console.log(name);
                var self = this;
                let fName = null;
                if(this.$route.query.printtype){
                    fName = this.$route.query.printtype == 'ab' ? "AccountBookPrintData" : "StockJournalPrintData";
                }
                else{
                    fName = "InvoiceData"
                }
                
                this.getData(fName, this.$route.query.tId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.data = JSON.parse(response);
                            self.templateNo = self.data.templateSettings.invoiceTemplate;
                            self.themeColor = self.data.templateSettings.themeColor;

                            self.previewTemplate = require('./invoices/template'+self.templateNo).default;
                            self.printTemplate = require('./invoices/template'+self.templateNo).default;

                            // self.previewTemplate = require('./invoices/template_test').default;
                            // self.printTemplate = require('./invoices/template_test').default;

                            if(self.templateNo == 101){
                                self.invoiceLoaded = true;

                                // sorting as per amount types
                                self.data.transactionLedgers.sort(function(a, b){
                                    return b.amountType - a.amountType;
                                });
                                // sorting transaction ledgers as per order number
                                self.data.transactionLedgers.sort(function(a, b){
                                    return a.orderNumber - b.orderNumber;
                                });
                            }
                            else{
                                if(self.data.transactionDetails.transactionListId == 10005){
									self.data.transactionDetails.invoiceTitle = self.$root.TransactionSettings.estimatePrintTitle ? self.$root.TransactionSettings.estimatePrintTitle : 'Estimate';
								}
                                else if(self.data.transactionDetails.transactionListId == 10004){
									self.data.transactionDetails.invoiceTitle = self.$root.TransactionSettings.saleReturnPrintTitle ? self.$root.TransactionSettings.saleReturnPrintTitle : 'Sale Return';
								}
								else if(self.data.transactionDetails.transactionListId == 20004){
									self.data.transactionDetails.invoiceTitle = self.$root.TransactionSettings.purchaseReturnPrintTitle ? self.$root.TransactionSettings.purchaseReturnPrintTitle : 'Purchase Return';
								}
                                else if(self.data.transactionDetails.transactionListId == 10003 && self.$root.CurrentBusiness.GSTRegistrationStatus != 1){
									if(self.$root.TransactionSettings.invoicePrintTitle && self.$root.TransactionSettings.invoicePrintTitle != 'Default'){
										self.data.transactionDetails.invoiceTitle = self.$root.TransactionSettings.invoicePrintTitle;
									}
								}
                            }
                            

                            if(self.data.transactionDetails && self.data.transactionDetails.transactionItems && self.data.transactionDetails.transactionItems.length){
                                self.hasItems = true;
                                self.fn_removeZeroTaxAddons();
                            }
                            else{
                                self.hasItems = false;
                            }

                            setTimeout(function(){
                                self.$refs['ref_previewTemplate'].fn_init(self.templateNo);
                            },300);

                            setTimeout(function(){
                                self.$refs['ref_printTemplate'].fn_init(self.templateNo, self.fn_calculateZoomPercent);
                                self.invoiceLoaded = true;
                                // self.$route.query.dp ? self.fn_print() : '';
                            },500);

                            setTimeout(function(){
                                // if(localStorage.getItem('from_preview_settings')){
                                //     localStorage.removeItem('from_preview_settings');
                                // }
                                // else{
                                //     Number(self.$route.query.dp) ? self.fn_print() : '';
                                // }
                                if(localStorage.getItem('direct_print')){
                                    self.fn_print();
                                }
                            },1000);


                        }, 
                        onError: function(error){
                            console.log(error);           
                        }
                    }
                );
            },

            fn_removeZeroTaxAddons: function(){
                for(let i=0; i<this.data.transactionDetails.transactionItems.length; i++){
                    let item = this.data.transactionDetails.transactionItems[i];
                    item.cols.addOns = item.cols.addOns.filter(addOnsItem => {
                        return addOnsItem.percentage != 0;
                    });
                }
            },

            fn_initPrintTemplate: function(type){
                let self = this;
                setTimeout(function(){
                    if(type=='print'){
                        self.fn_printInvoice();
                    }
                    else{
                        self.$root.fn_checkStoragePermission(function(){
                            if(type=='sharewhatsapp'){
                                self.fn_shareInvoice('sharewhatsapp');
                            }
                            else if(type=='share'){
                                self.fn_shareInvoice('share');
                            }
                            else{
                                self.fn_exportToPdf(null);
                            }
                        })
                    }
                },300);
            },

            fn_printInvoice: function(){
                let transactionNumber = null;
                // let downloadTime = null;
                if(this.data.transactionDetails){
                    transactionNumber = this.data.transactionDetails.transactionNumber ? this.data.transactionDetails.transactionNumber : '';
                    // downloadTime = this.data.transactionDetails.transactionDate ? new Date(this.data.transactionDetails.transactionDate) : new Date();
                }
                else{
                    transactionNumber = this.data.transactionNumber ? this.data.transactionNumber : '';
                    // downloadTime = this.data.transactionDate ? new Date(this.data.transactionDate) : new Date();
                }

                let title = null;
                if(this.data.transactionDetails){
                    if(this.data.transactionDetails.transactionListId==10003){
                        title = "Invoice";
                    }
                    else{
                        title = this.fn_getTransactionTitle(this.data.transactionDetails.transactionListId);    
                    }
                }
                else{
                    title = this.data.invoiceTitle;
                }

                let fileName = title.replace(/ /g,'') + '-' + transactionNumber.replace(/\//g,'') + '_' + this.$root.fn_getCurrentDateTimeString();
                console.log(fileName);
                // AndroidRoutingTest.print(fileName);
            },

            fn_shareInvoice: function(type){

                let shareObj = {};

                let title = null;
                if(this.data.transactionDetails){
                    if(this.data.transactionDetails.transactionListId==10003){
                        title = "Invoice";
                    }
                    else{
                        title = this.fn_getTransactionTitle(this.data.transactionDetails.transactionListId);    
                    }
                }
                else{
                    title = this.data.invoiceTitle;
                }

                shareObj.subject = title + " Details";
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = null;
                shareObj.email = null;
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';

                let partyName = '';
                let transactionDate = null;
                let roundedAmount = null;
                if(this.data.transactionDetails){
                    shareObj.primaryMobileNumber = this.data.transactionDetails.partyDetails.primaryMobileNumber && type == 'sharewhatsapp' ? this.data.transactionDetails.partyDetails.primaryMobileNumber : null;
                    shareObj.email = this.data.transactionDetails.partyDetails.email ? [this.data.transactionDetails.partyDetails.email] : null ;
                    partyName = this.data.transactionDetails.partyDetails.partyName;
                    transactionDate = this.data.transactionDetails.transactionDate;
                    roundedAmount = this.data.transactionDetails.roundedAmount;
                }
                else{
                    shareObj.primaryMobileNumber = this.data.partyDetails.primaryMobileNumber && type == 'sharewhatsapp' ? this.data.partyDetails.primaryMobileNumber : null;
                    shareObj.email = this.data.partyDetails.email ? [this.data.partyDetails.email] : null ;
                    partyName = this.data.partyDetails.partyName;
                    transactionDate = this.data.transactionDate;
                    roundedAmount = this.data.roundedAmount ? this.data.roundedAmount : 0;
                }
                
                let party = partyName ? partyName + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + title + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;

                this.fn_exportToPdf(shareObj);
            },

            fn_exportToPdf: function(shareObj){
                let self = this;
                let transactionNumber = null;
                // let downloadTime = new Date();
                if(this.data.transactionDetails){
                    transactionNumber = this.data.transactionDetails.transactionNumber ? this.data.transactionDetails.transactionNumber : '';
                }
                else{
                    transactionNumber = this.data.transactionNumber ? this.data.transactionNumber : '';
                }

                let title = null;
                if(this.data.transactionDetails){
                    if(this.data.transactionDetails.transactionListId==10003){
                        title = "Invoice";
                    }
                    else{
                        title = this.fn_getTransactionTitle(this.data.transactionDetails.transactionListId);    
                    }
                }
                else{
                    title = this.data.invoiceTitle;
                }
                
                let fileName = title.replace(/ /g,'') + '-' + transactionNumber.replace(/\//g,'') + '_' + this.$root.fn_getCurrentDateTimeString();

                this.exportToPdf(fileName+'.pdf',{

                  onSuccess: function(response){
                        console.log(response);
                        if(shareObj != undefined)
                            self.$root.fn_share(shareObj);
                        else{
                            self.showToast('Invoice downloaded');
                            self.snackbar = true;
                        }
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.showToast('Invoice download failed');
                  }
                });
            },

            fn_getTransactionTitle: function(transactionListId){
                for(let i=0; i<transactionForms.length; i++){
                    if(transactionForms[i].id == transactionListId){
                        return transactionForms[i].title;
                    }
                }
            },

            fn_getBottomMenu: function () {

                var bottomMenu = {"BottomMenu": [menuItem.reset, menuItem.share]};
                let tagId = 'preview'
                this.setBottomMenu(tagId, bottomMenu, 
                    {
                        onSuccess: function (response) {
                            console.log(response);
                        },
                        onError: function (error) {
                            console.log(error);
                        }
                    }
                )
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                // tagId id form future use
                // menuId id form future use
                if(menuAction=='reset'){
                    this.fn_reset();
                }
                else if(menuAction=='share'){
                    this.fn_shareInvoice('share');
                }
                else if(menuAction=='help'){
                    this.$router.push("/settings");
                }
            },

            fn_openPermissionDialog: function(permissionType){
                this.$root.permissionType = permissionType;
                this.$refs['ref_dialogPermission'].dialog_permission = true;
            },

            fn_previewSettings: function(){
                this.$router.push(
                    {
                        // path:"/"+this.$root.CurrentBusiness.BusinessID+"/"+this.$route.params.listId+"/"+this.$route.params.type+"/items/"+this.$route.params.tagId+"/"+this.$route.params.transactionId+'/settings', 
                        // query:{title: this.$route.params.type, listId:this.$route.query.listId, tId:this.$route.query.tId, template_no:this.$route.query.template_no, type:this.$route.query.type, printtype:this.$route.query.printtype, transactionTypeId:this.$route.query.transactionTypeId, dp:0, invoiceFor:this.invoiceForText}
                        path:"/"+this.$root.CurrentBusiness.BusinessID+"/"+this.$route.params.listId+"/"+this.$route.params.type+"/items/"+this.$route.params.tagId+"/"+this.$route.params.transactionId+'/settings', 
                        query:{title: this.$route.params.type, listId:this.$route.query.listId, tId:this.$route.query.tId, template_no:this.$route.query.template_no, type:this.$route.query.type, printtype:this.$route.query.printtype, transactionTypeId:this.$route.query.transactionTypeId, invoiceFor:this.invoiceForText}
                    }
                ).catch(err=>{
                    console.log(err);
                });
            },

            fn_closeDialog: function(){
                this.$refs['ref_dialogPermission'].dialog_permission = false;
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted(){
            // window.js_globals.fn_openPermissionDialog = this.fn_openPermissionDialog;
            // window.js_globals.fn_onBackPress = this.fn_goBack;
            this.fn_bindInvoiceTemplate();

            this.$eventHub.$on('download', () => {
                this.fn_export();
            });

            let isCalled = false;
            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.fn_bindInvoiceTemplate();
                    }
                }, 1000);
            });
        },
        computed:{
            zoomOutPercent: function(){
                if(document.getElementById("body_template_"+this.templateNo)){
                    let bodyTableElement = document.getElementById("body_template_"+this.templateNo);
                    let percentage = ((window.innerWidth - 32) / bodyTableElement.offsetWidth) * 100;
                    console.log(percentage);
                    return percentage;
                }
                else{
                    return "100%";
                }
                
            }
        },
        beforeRouteUpdate(to, from, next){
            // alert('up');
            console.log(to);
            console.log(from);
            next(true)
            this.fn_bindInvoiceTemplate();

            let isCalled = false;
            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.fn_bindInvoiceTemplate();
                    }
                }, 1000);
            });
        },
        beforeRouteLeave (to, from, next) {
            this.$eventHub.$off('download');
            this.$eventHub.$off('connected');
            next();
        },
        // beforeRouteLeave(to, from, next){
        //     if(this.$refs['ref_dialogPermission'].dialog_permission){
        //         this.$refs['ref_dialogPermission'].closeDialogWeb();
        //         next(false);
        //     }
        //     else{
        //         next();
        //     }
        // },
    }
</script>