<template>
    <div full-height>
        <div class="display_wrapper" id="non-printable">

            <v-toolbar flat :height="56">
                <v-btn icon @click="fn_goBack" v-if="$route.query.back"><v-icon>arrow_back</v-icon></v-btn>

                <v-toolbar-title :class="{'two-line-title':offsetTop>=36}">
                    {{toolbarTitle}}
                    <div class="toolbar-subtitle" v-if="displayData && offsetTop>=36">
                        <div class="subtitle-text dark-opacity-color">
                            {{displayData.mergeTransactionNumber}}
                        </div>
                    </div>
                    
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn outlined small @click="fn_previewInvoice(true, 'sales')" color="primary" v-if="displayData">
                    <v-icon left>preview</v-icon> Preview
                </v-btn>

                <v-btn outlined class="ml-4" small @click="fn_edit" color="primary" v-if="displayData && displayData.status==1">
                    <v-icon left>edit</v-icon> Edit
                </v-btn>

                <v-btn class="ml-4" small outlined color="primary" v-if="settings.transactionTypeId == 10003 && displayData && displayData.status==1" @click="fn_copy">
                    <v-icon left>content_copy</v-icon> Copy
                </v-btn>

                <v-btn class="ml-4" small outlined color="warning" v-if="displayData && displayData.status==1" @click="fn_cancel">
                    <v-icon left>close</v-icon> Cancel
                </v-btn>

                <v-btn class="ml-4" small outlined color="red" v-if="displayData" @click="fn_delete">
                    <v-icon left>delete</v-icon> Delete
                </v-btn>

                <!-- <v-btn icon @click="fn_edit" v-if="displayData && displayData.status==1">
                    <v-icon>edit</v-icon>
                </v-btn> -->

            </v-toolbar>

            <v-divider></v-divider>

            <div class="data-container grey-back" v-scroll="onScrollTransactionForm" v-if="displayData">

                <v-alert :height="40" class="pa-2 no-border-radius mb-0" :value="displayData && displayData.status==0" type="warning" icon="info_outline" transition="slide-y-transition">
                    <span>This transaction has been cancelled!</span>
                </v-alert>

                <v-layout row wrap pa-4 ma-0 font16 white-back>
                    <v-flex xs6 sm6 md6>{{displayData.mergeTransactionNumber}}</v-flex>
                    <v-flex xs6 sm6 md6 right-align>{{displayData.transactionDate | moment("DD-MMM-YYYY")}}</v-flex>
                </v-layout>

                <!-- <v-layout grid wrap text-xs-center>
                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('sharewhatsapp')">
                        <div right-border>
                            <div><v-icon>send</v-icon></div>
                            <div class="font-weight-bold">Send</div>
                        </div>  
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('print')">
                        <div right-border>
                            <div><v-icon>print</v-icon></div>
                            <div class="font-weight-bold">Print</div>
                        </div>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('pdf')">
                        <div right-border>
                            <div><v-icon>import_export</v-icon></div>
                            <div class="font-weight-bold">Export</div>
                        </div>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_getBottomMenu">
                        <div>
                            <div><v-icon>more_horiz</v-icon></div>
                            <div class="font-weight-bold">More</div>
                        </div>
                    </v-flex>
                </v-layout> -->

                <v-list nopadding class="mt-2">
                    <!-- <v-list-item @click="fn_viewPartyDetails(displayData.partyId)"> -->
                    <v-list-item @click="fn_viewPartyDetails(displayData.partyId)">
                        <v-list-item-content>
                            <v-list-item-title>Customer</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action primary-color font16>{{displayData.partyName}}</v-list-item-action>
                    </v-list-item>

                    <v-list-item v-if="displayData && (displayData.placeOfSupplyId != $root.CurrentBusiness.StateID) && !displayData.exportType">
                        <v-list-item-content>
                          <v-list-item-title>Place of Supply</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>{{displayData.placeOfSupplyName}}</v-list-item-action>
                    </v-list-item>

                    <template v-if="displayData && displayData.exportType">
                        <v-list-item v-if="displayData.placeOfSupplyCountryName">
                            <v-list-item-content>
                              <v-list-item-title>Country</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>{{displayData.placeOfSupplyCountryName}}</v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Export Type</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>{{displayData.exportType}}</v-list-item-action>
                        </v-list-item>
                    </template>

                </v-list>

                    <v-list nopadding v-if="fn_checkForItems()">

                        <div v-for="(groupItems, category, index) in filteredItems" :key="index">

                            <div v-for="(group, groupIndex) in groupItems" :key="groupIndex">

                                <v-divider></v-divider>

                                <v-list-item v-if="group.items && group.items.length">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{group.header}}&nbsp;
                                            <span v-if="group.baseTransactionNumber != displayData.mergeTransactionNumber">
                                                {{group.baseTransactionNumber}} 
                                            </span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <!-- <v-subheader class="subheader-dense" v-if="group.items && group.items.length">
                                {{group.header}}&nbsp;
                                <span v-if="group.baseTransactionNumber != displayData.mergeTransactionNumber">
                                    {{group.baseTransactionNumber}} 
                                </span>
                                </v-subheader> -->

                                <v-divider></v-divider>

                                <div v-for="(item, index) in group.items" :key="index">

                                    <v-list-item class="right-subtitle-tile custom-action-tile">
                                        <v-list-item-content>
                                          <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>
                                          <!-- <v-list-item-subtitle class="mb-1">
                                            <span class="right_subtitle">
                                                &#8377; {{item.netAmount ? $root.numberToLocale(item.netAmount) : item.netAmount}}
                                            </span> 
                                          </v-list-item-subtitle> -->

                                          <v-list-item-subtitle class="mb-1" v-for="(variant, vIndex) in item.productVariants" :key="vIndex">
                                            <template v-if="variant.qty">
                                                {{variant.qty}} {{variant.unitName}} X {{item.rate ? $root.numberToLocale(item.rate) : item.rate}}
                                            </template>
                                          </v-list-item-subtitle>

                                          <v-list-item-subtitle class="mb-1" v-if="item.groupTypeId==101 && $root.TransactionSettings.mrpWiseStock">
                                            MRP: &#8377; {{item.mrp ? $root.numberToLocale(item.mrp) : item.mrp}}
                                          </v-list-item-subtitle>


                                          <template v-for="(addOns, addOnsIndex) in item.transactionProductAddOns">
                                            <v-list-item-subtitle class="mb-1" :key="addOnsIndex" v-if="addOns.value && !addOns.isGst">
                                                {{addOns.name}}
                                                <span v-if="addOns.percentage"> @{{addOns.percentage}}%</span>: {{addOns.nature ? '-' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}
                                            </v-list-item-subtitle>    
                                          </template>

                                          <v-list-item-subtitle class="mb-1" v-if="displayData.taxMode">
                                            GST @{{item.taxpercent}}%: {{item.taxvalue ? $root.numberToLocale(item.taxvalue) : item.taxvalue}}
                                          </v-list-item-subtitle>

                                          <v-list-item-subtitle class="mb-1" v-if="displayData.taxMode && item.cessvalue">
                                            Cess<span v-if="item.cesspercent!=null"> @{{item.cesspercent}}%</span>: {{item.cessvalue ? $root.numberToLocale(item.cessvalue) : item.cessvalue}}
                                          </v-list-item-subtitle>

                                          <v-list-item-subtitle class="mb-1" v-if="item.description">
                                            {{item.description}}
                                          </v-list-item-subtitle>

                                        </v-list-item-content>

                                        <v-list-item-action font16>
                                            &#8377; {{item.netAmount ? $root.numberToLocale(item.netAmount) : item.netAmount}}
                                        </v-list-item-action>

                                    </v-list-item>

                                    <div class="grey-back full-width batch_display_container" :class="{'pb-1':group.items.length - 1 != index}" v-if="item.batches && item.batches.length">

                                        <v-expansion-panels nomargin flat>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header>
                                                    <template>
                                                        <v-subheader dense class="pa-0">{{item.selectedBatchType == 2 ? '#Series' : '#Batches'}}</v-subheader>
                                                    </template>
                                                </v-expansion-panel-header>

                                                <v-divider></v-divider>

                                                <v-expansion-panel-content>
                                                    <template v-for="(batch, batchIndex) in item.batches">
                                                        <v-card class="pa-2 pb-0 pl-4" flat :key="batchIndex">
                                                            <v-card-text class="pa-0">
                                                                <v-layout ma-0 row wrap>
                                                                    <v-flex xs3 sm3 md3 mb-1 v-if="batch.batchNumber">
                                                                        <div light-opacity-color>Batch No.</div>
                                                                        <div>{{ batch.batchNumber }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-1 v-if="batch.batchSize">
                                                                        <div light-opacity-color>Size</div>
                                                                        <div>{{ batch.batchSize }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-1 v-if="batch.batchMRP">
                                                                        <div light-opacity-color>MRP</div>
                                                                        <div>{{ batch.batchMRP }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-1 v-if="batch.mfgDate">
                                                                        <div light-opacity-color>Mfg. Date</div>
                                                                        <div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.expDate">
                                                                        <div light-opacity-color>Exp. Date</div>
                                                                        <div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.purchaseRate">
                                                                        <div light-opacity-color>Purchase Rate</div>
                                                                        <div>{{ batch.purchaseRate }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field1">
                                                                        <div light-opacity-color>Serial Number</div>
                                                                        <div>{{ batch.field1 }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field2">
                                                                        <div light-opacity-color>IMEI Number</div>
                                                                        <div>{{ batch.field2 }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field3">
                                                                        <div light-opacity-color>Part Number</div>
                                                                        <div>{{ batch.field3 }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-2 v-if="batch.field4">
                                                                        <div light-opacity-color>Model Number</div>
                                                                        <div>{{ batch.field4 }}</div>
                                                                    </v-flex>
                                                                    <v-flex xs3 sm3 md3 mb-2>
                                                                        <div light-opacity-color>Quantity</div>
                                                                        <div>{{ batch.qty }}</div>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-card-text>
                                                        </v-card>
                                                        <v-divider :key="'divider'+batchIndex" v-if="item.batches.length - 1 != batchIndex"></v-divider>
                                                    </template>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                        <!-- <v-expansion-panel nomargin>

                                            <v-expansion-panel-content>

                                                <div slot="header" light-opacity-color font16 class="pt-0 pb-0">#Batches</div>
                                                <v-card flat v-for="(batch, batchIndex) in item.batches" :key="batchIndex">
                                                    <v-card-text class="pb-2 pt-2">
                                                        <v-layout row wrap>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchNumber">
                                                                <div light-opacity-color>Batch No.</div>
                                                                <div>{{ batch.batchNumber }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchSize">
                                                                <div light-opacity-color>Size</div>
                                                                <div>{{ batch.batchSize }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchMRP">
                                                                <div light-opacity-color>MRP</div>
                                                                <div>{{ batch.batchMRP }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.mfgDate">
                                                                <div light-opacity-color>Mfg. Date</div>
                                                                <div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.expDate">
                                                                <div light-opacity-color>Exp. Date</div>
                                                                <div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.purchaseRate">
                                                                <div light-opacity-color>Purchase Rate</div>
                                                                <div>{{ batch.purchaseRate }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field1">
                                                                <div light-opacity-color>Serial Number</div>
                                                                <div>{{ batch.field1 }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field2">
                                                                <div light-opacity-color>IMEI Number</div>
                                                                <div>{{ batch.field2 }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field3">
                                                                <div light-opacity-color>Part Number</div>
                                                                <div>{{ batch.field3 }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field4">
                                                                <div light-opacity-color>Model Number</div>
                                                                <div>{{ batch.field4 }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2>
                                                                <div light-opacity-color>Quantity</div>
                                                                <div>{{ batch.qty }}</div>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-card-text>
                                                    <v-divider v-if="item.batches.length - 1 != batchIndex"></v-divider>
                                                </v-card>
                                            </v-expansion-panel-content>

                                        </v-expansion-panel> -->

                                        </div>
                                </div>

                                <v-divider v-if="groupItems.length-1 != groupIndex"></v-divider>

                            </div>


                        </div>
                    </v-list>


                    <v-divider v-if="fn_checkForItems()"></v-divider>

                    <v-list class="oneline-tile-dense" nopadding v-if="fn_checkForItems()">

                    <!--    <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Total Qty</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{totalQty}} Units</v-list-item-action>
                        </v-list-item>
 -->
                        <!-- <v-divider></v-divider> -->

                        <v-list-item>
                            <v-list-item-content>
                              <!-- <v-list-item-title>Gross Amount</v-list-item-title> -->
                              <v-list-item-title>Items Total</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>{{displayData.grossAmount ? $root.numberToLocale(displayData.grossAmount) : displayData.grossAmount}}</v-list-item-action>
                        </v-list-item>

                        <v-list-item v-for="(addOns, index) in addOnsSummary" :key="index">
                            <v-list-item-content>
                              <!-- <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title> -->
                              <v-list-item-title>{{addOns.addOnsNature ? 'Items Discount' : addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>{{addOns.addOnsNature ? '-' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>

                        <v-list-item v-for="(addOns, index) in gstAddOns" :key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>{{addOns.addOnsNature ? '-' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <!-- <v-divider></v-divider> -->

                    <!-- <v-list nopadding>
                        <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Net Amount</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>&#8377; {{displayData.netAmount ? $root.numberToLocale(displayData.netAmount) : displayData.netAmount}}</v-list-item-action>
                        </v-list-item>
                    </v-list> -->

                    <!-- <v-list nopadding margin-top-ten v-if="displayData.transactionAddOns && displayData.transactionAddOns.length">
                        <v-subheader>Discounts/Taxes/Other Charges</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </v-list> -->

                    <!-- <div nopadding white-back margin-top-ten v-if="isOtherChargesAddons">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title medium-theme-color>Other Charges</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index" v-if="addOns.addOnsType==3">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </div>

                    <div nopadding white-back margin-top-ten v-if="isDiscountAddons">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title medium-theme-color>Discounts</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index" v-if="addOns.addOnsType==1">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </div>

                    <div nopadding white-back margin-top-ten v-if="isTaxAddons">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title medium-theme-color>Taxes</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list-item v-for="(addOns, index) in displayData.transactionAddOns" :key="index" v-if="addOns.addOnsType==2">
                            <v-list-item-content>
                              <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>
                        </v-list-item>
                    </div> -->

                    <div class="oneline-tile-dense mt-2" full-width v-if="displayData.transactionAddOns && displayData.transactionAddOns.length">
                        
                        <div white-back v-if="isOtherChargesAddons">
                            <v-list-item v-for="(addOns, index) in otherChargesAddons" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action font16>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>    
                            </v-list-item>
                        </div>

                        <div white-back v-if="isDiscountAddons">
                            <v-list-item v-for="(addOns, index) in discountAddons" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action font16>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>    
                            </v-list-item>
                        </div>

                        <div white-back v-if="isTaxAddons">
                            <v-list-item v-for="(addOns, index) in taxAddons" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{addOns.addOnsName}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action font16>{{addOns.addOnsNature ? '(-)' : ''}}{{addOns.value ? $root.numberToLocale(addOns.value) : addOns.value}}</v-list-item-action>    
                            </v-list-item>
                        </div> 
                        
                    </div>

                    <!-- <v-list nopadding margin-top-ten> -->
                    <v-list class="oneline-tile-dense" nopadding>

                        <v-list-item v-if="roundOffValue">
                            <v-list-item-content>
                              <v-list-item-title>Round Off</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{roundOffValue}}</v-list-item-action>
                        </v-list-item>

                        <!-- <v-divider></v-divider>

                        <v-list-item font-medium sticky-bottom>
                            <v-list-item-content>
                              <v-list-item-title>Total Amount</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{displayData.roundedAmount ? $root.numberToLocale(displayData.roundedAmount) : displayData.roundedAmount}}</v-list-item-action>
                        </v-list-item> -->
                    </v-list>

                    <v-list sticky-bottom nopadding>
                        <v-divider></v-divider>
                        <v-list-item font-medium>
                            <v-list-item-content>
                              <v-list-item-title>Total Amount</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{displayData.roundedAmount ? $root.numberToLocale(displayData.roundedAmount) : displayData.roundedAmount}}</v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <v-list nopadding>
                        <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Term of Payment</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{displayData.paymentTerm != -1 ? displayData.paymentTerm + ' Days' : 'No Due Date'}}</v-list-item-action>
                        </v-list-item>

                        <v-list-item v-if="displayData.paymentTerm != -1">
                            <v-list-item-content>
                              <v-list-item-title>Due Date</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>{{fn_dueDate(displayData.refDate, displayData.transactionDate, displayData.paymentTerm) | moment("DD-MMM-YYYY")}}</v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <div v-if="displayData.description" class="mt-2">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Description</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-card flat class="note-card">
                          <v-card-text font16 v-html="displayData.description" white-space-pre-line></v-card-text>
                        </v-card>
                    </div>

                    <div v-if="displayData.notes" class="mt-2">
                        <v-list full-width nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Notes</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-card flat class="note-card">
                          <v-card-text dark-opacity-color font16 v-html="displayData.notes" white-space-pre-line></v-card-text>
                        </v-card>
                    </div>

                
                <v-layout row wrap mt-2 ma-0 white-back v-if="customFields.length">

                    <!-- {{ customFields }} -->

                    <v-expansion-panels nomargin>

                        <v-expansion-panel v-for="(item, index) in customFields" :key="index">

                          <!-- <v-divider></v-divider> -->

                          <!-- <div slot="header" font16>{{item.sectionName}}</div> -->
                          <v-expansion-panel-header>{{ item.sectionName }}</v-expansion-panel-header>

                          <!-- <v-divider class="pb-3"></v-divider> -->

                          <v-expansion-panel-content>
                            <v-card v-for="(fieldItem, itemIndex) in item.items" :key="itemIndex">
                                <template v-if="fieldItem.fieldValue">
                                    <v-card-text class="pt-0">
                                        <div font12 light-theme-color>{{fieldItem.fieldName}}</div>
                                        <div font16 v-if="fieldItem.fieldDataType == 'yes/no'">{{fieldItem.fieldValue ? 'Yes' : 'No'}}</div>

                                        <div font16 v-else-if="fieldItem.fieldDataType == 'dateTime'">
                                            <span v-if="fieldItem.fieldValue">{{fieldItem.fieldValue | moment("DD-MMM-YYYY")}}</span>
                                            <span v-else>-</span>
                                        </div>
                                        
                                        <div font16 v-html="fieldItem.fieldValue ? fieldItem.fieldValue : '-'" white-space-pre-line v-else></div>
                                    </v-card-text>    
                                </template>
                            </v-card>
                            </v-expansion-panel-content>

                        </v-expansion-panel>

                      </v-expansion-panels>

                </v-layout>

                <div v-if="displayData.documentDetails">
                    <v-list full-width nopadding>
                        <v-list-item>
                            <v-list-item-content>
                              <!-- <v-list-item-title>Attachments</v-list-item-title> -->
                              <v-list-item-title>Attachments {{ displayData.documentDetails.length ? '('+displayData.documentDetails.length+')' : '' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <template v-for="(item, index) in displayData.documentDetails">
                        <v-list-item white-back :key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{item.fileName}}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <!-- <v-btn text icon color="primary" :disabled="$root.CurrentBusiness.temporaryBusiness" @click.native="fn_checkFileExist(item)"><v-icon>cloud_download</v-icon></v-btn> -->
                                <v-btn text icon :disabled="$root.CurrentBusiness.temporaryBusiness ? true : false" @click.native="fn_checkFileExist(item)"><v-icon>cloud_download</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="displayData.documentDetails.length-1 != index" :key="item.fileName"></v-divider>
                    </template>
                </div>

                <v-list nopadding class="mt-2" v-if="displayData && displayData.paymentMode==1">
                    <!-- <v-subheader full-width border-bottom-thin nomargin norightpadding>Payment Mode</v-subheader> -->
                    <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Payment Mode</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <div v-for="(item, index) in displayData.transactionLedgers" :key="index">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.accountLedgerId == creditAccountLedgerId ? 'On Credit' : item.accountLedgerName}}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action font16>&#8377; {{item.amount ? $root.numberToLocale(item.amount) : item.amount}}</v-list-item-action>
                        </v-list-item>  
                    </div>
                </v-list>

                <!-- <v-footer class="invoice-footer-actions">

                    <v-layout white-back grid wrap text-xs-center>
                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('sharewhatsapp')">
                            <div right-border>
                                <div><v-icon>send</v-icon></div>
                                <div font11 class="font-weight-bold">Send</div>
                            </div>  
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('share')">
                            <div right-border>
                                <div><v-icon>share</v-icon></div>
                                <div font11 class="font-weight-bold">Share</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('print')">
                            <div right-border>
                                <div><v-icon>print</v-icon></div>
                                <div font11 class="font-weight-bold">Print</div>
                            </div>
                        </v-flex>

                        <v-flex xs3 sm3 md3 pt-2 pb-2 v-ripple @click="fn_bindInvoiceTemplate('pdf')">
                            <div right-border>
                                <div><v-icon>vertical_align_bottom</v-icon></div>
                                <div font11 class="font-weight-bold">Download</div>
                            </div>
                        </v-flex>

                    </v-layout>

                    <div v-ripple class="pb-2 pt-2" center-align style="width:20%" white-back @click="fn_getBottomMenu">
                        <div><v-icon>more_horiz</v-icon></div>
                        <div font11 class="font-weight-bold">More</div>
                    </div>
                </v-footer> -->

            </div>

            <v-row class="fill-height" align-content="center" justify="center" style="position:absolute;top:0;bottom:0;width: calc(100% - 280px);" v-if="loading">
              <v-col cols="6">
                <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
              </v-col>
            </v-row>

            <!-- <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" :header="confirmationDialogHeader" :confirmationMessage="confirmationMessage" :actionMethod="fn_callCancelTransaction" :params="{type:actionType}" :displayView='true'></dialog-confirmation> -->

            <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>
        </div>

        <!-- <div id="printable" full-width>
            <component :is="printTemplate" ref="ref_printTemplate" :data="printData" v-if="printTemplate && printData!=null"></component>
        </div> -->

        <!-- <dialog-permission ref="ref_dialogPermission"></dialog-permission>
        <dialog-loading ref="ref_dialogLoading" message="Downloading..."></dialog-loading> -->

        <dialog-loading ref="ref_dialogLoading" message="Downloading..." :value="loadingStatusObj.value"></dialog-loading>

        <dialog-confirmation ref="ref_dialogCancelDeleteTransaction" :header="confirmationDialogHeader" :confirmationMessage="confirmationMessage" :actionMethod="fn_callCancelTransaction" :params="{type:actionType}" :displayView='true' okBtnText="Yes" cancelBtnText="No" icon="warning" iconClass="redcolor" textClass="redcolor"></dialog-confirmation>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Batch Alert!" confirmationMessage="This transaction consists batch.<br>Please edit this transaction in BizOps App on mobile"  :actionMethod="fn_closeDialogConfirmation" :displayView='true' okBtnText="Okay"></dialog-confirmation>

        <v-snackbar v-model="snackbarOpenFile">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
const menuItem = require('../../../../config.js').configs.menuItem;
const transactionForms = require('../../../../config.js').configs.transactionForms;
const transactionTypes = require('../../../../config.js').configs.transactionTypes;

import dialogConfirmation from '../../../dialogs/dialog_confirmation'
import dialogLoading from '../../../dialogs/dialog_loading'

import {transactionMixin} from '../../transaction_mixin'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{
                displayData:{},
                toolbarTitle:null,
                selectedNatureValue:null,
                snackbar:false,
                snackbar_msg:null,
                addOnsSummary:[],
                customFields:[],
                itemsQuanity:[],

                pendingOrders:[],
                pendingChallans:[],
                pendingInvoices:[],
                actionType:null,
                confirmationDialogHeader:null,
                confirmationMessage:null,
                gstAddOns:[],
                printData:null,
                printTemplate:null,
                isTaxAddons:false,
                isDiscountAddons:false,
                isOtherChargesAddons:false,
                loading:false,
                hasBatches:false
            }
        },
        components:{
         'dialog-confirmation': dialogConfirmation,
         'dialog-loading':dialogLoading
        },
        methods:{

            fn_addonItems: function(addons){
                return addons.filter(item=>{
                    return item.value && !item.isGst;
                })
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_shareInvoice: function(type){
                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';

                // if(this.displayData.transactionTypeFormName == "Retail Invoice" || this.displayData.transactionTypeFormName == "Wholesale Invoice" || this.displayData.transactionTypeFormName == "Export Invoice" || this.displayData.transactionTypeFormName ==  "Stock Transfer"){
          //           this.displayData.transactionTypeFormName = "Invoice";
          //       }

                let title = null;
                if(this.displayData.transactionListId==10003){
                    title = "Invoice";
                }
                else{
                    title = this.toolbarTitle;
                }
                
                // shareObj.shareVia = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? 'ShareOnWhatsApp' : 'Share';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = this.displayData.primaryMobileNumber && type == 'sharewhatsapp' ? this.displayData.primaryMobileNumber : null;
                shareObj.subject = title + " Details";
                shareObj.email = this.displayData.email ? [this.displayData.email] : null ;

                let party = this.displayData.partyName ? this.displayData.partyName + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + title + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(this.displayData.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(this.displayData.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                
                // shareObj.textMessage = "Dear "+this.displayData.partyName+",\n\nBelow are your invoice details. Please find the attached pdf of your invoice.\n\nInvoice Date: "+this.$moment(this.displayData.createdDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(this.displayData.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData('InvoiceData', this.displayData.transactionId, 'pdf', shareObj);
            },

            fn_callCancelTransaction: function(paramObj){
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;

                let obj = {};
                obj.transactionTypeId = this.displayData.transactionListId;

                let self = this;
                if(paramObj.type=='cancel'){
                    this.fn_cancelTransaction(this.displayData.transactionId, this.displayData.transactionListId, null, function(status){
                        self.displayData.status = status;
                        if(status==0){
                            // alert("Transaction "+self.displayData.mergeTransactionNumber+ " cancelled");
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " cancelled"});
                            // self.$eventHub.$emit('UpdateSaleList', obj);
                            if(self.$route.params.listId == 'parties' || self.$route.params.listId == 'ledgers'){
                                self.$eventHub.$emit('UpdateLedgerList', {});
                            }
                            else{
                                self.$eventHub.$emit('UpdateSaleList', obj);
                            }
                        }
                        else{
                            self.$eventHub.$emit('snackbarAlert', {msg:"Transaction "+self.displayData.mergeTransactionNumber+ " not cancelled"});
                        }
                    });  
                }
                else{
                    let transactionNumber = this.displayData.mergeTransactionNumber;
                    this.fn_deleteTransaction(this.displayData.transactionId, this.displayData.transactionListId, function(status){
                        if(status){
                            self.$eventHub.$emit('snackbarAlert', {msg:'#'+transactionNumber+ ' deleted parmanently'});
                            // self.$eventHub.$emit('UpdateSaleList', obj);
                            if(self.$route.params.listId == 'parties' || self.$route.params.listId == 'ledgers'){
                                self.$eventHub.$emit('UpdateLedgerList', {});
                            }
                            else{
                                self.$eventHub.$emit('UpdateSaleList', obj);
                            }
                            self.$router.push({path:"/"+self.$root.CurrentBusiness.BusinessID+"/"+self.$route.params.listId});
                        }
                        else{
                            // self.snackbar = true;
                            // self.snackbar = 'Something went wrong. Try again!.'; 
                            // alert('Something went wrong. Try again');
                            self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                        }
                    });
                }
            },

            formType: function(){
                if(this.settings.transactionTypeId == transactionTypes.order.id)
                    return 'order';
                else if(this.settings.transactionTypeId == transactionTypes.deliveryChallan.id)
                    return 'challan';
                else if(this.settings.transactionTypeId == transactionTypes.salesInvoice.id)
                    return 'invoice';
                else if(this.settings.transactionTypeId == transactionTypes.salesReturn.id)
                    return 'return';
            },

            fn_initializeSettings: function () {
                transactionForms.filter(item => {
                    if(item.id == this.settings.transactionTypeId){
                        this.toolbarTitle = item.title;
                        this.selectedNatureValue = item.selectedNature;
                    }
                });
            },

            fn_comingSoon: function () {
                this.showToast("Coming Soon..");
            },

            fn_get: function(itemId){
                var self = this;
                this.displayData = null;
                this.loading = true;
                this.getData('Sale', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.displayData = JSON.parse(response);
                            if(self.displayData.transactionProducts && self.displayData.transactionProducts.length){
                                self.hasBatches = false;
                                self.fn_bindData();
                                self.fn_calculateAddOnsSummary();
                                self.fn_itemsSummary();
                            }
                            self.fn_bindCustomFields();
                            if(self.displayData.transactionAddOns && self.displayData.transactionAddOns.length){
                                self.fn_checkTransactionAddOns();   
                            }
                            // self.fn_itemsSummary();
                            // self.fn_bindInvoiceTemplate();
                            self.loading = false;
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.loading = false;
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                        }
                    }
                );
            },

            fn_bindData: function(){

                //adding order and challan items to pending orders and pending challans for data grouping

                let self = this;

                if(this.settings.transactionTypeId == transactionTypes.salesInvoice.id || this.settings.transactionTypeId == transactionTypes.salesReturn.id || this.settings.transactionTypeId == transactionTypes.deliveryChallan.id){

                    this.displayData.transactionProducts.forEach(function (item) {
                
                        if(item.baseTransactionListId == transactionTypes.order.id){
                            assignPendingItems(self.pendingOrders);
                        }

                        else if(item.baseTransactionListId == transactionTypes.deliveryChallan.id){
                            assignPendingItems(self.pendingChallans);
                        }

                        else if(item.baseTransactionListId == transactionTypes.salesInvoice.id){
                            assignPendingItems(self.pendingInvoices);
                        }

                        function assignPendingItems(pendingItems) {
                            
                            let itemIndex = pendingItems.findIndex(pItem=>{
                                return pItem.baseTransactionNumber == item.baseTransactionNumber;
                            });

                            if(itemIndex==-1){
                                let obj = {};
                                obj.baseTransactionNumber = item.baseTransactionNumber;
                                obj.baseTransactionListId = item.baseTransactionListId;
                                obj.baseTransactionId = item.baseTransactionId;
                                obj.transactionProducts = [];
                                obj.transactionProducts.push(item);
                                pendingItems.push(obj);
                            }
                            else{
                                pendingItems[itemIndex].transactionProducts.push(item);
                            }
                        }

                        if(item.transactionProductBatches && item.transactionProductBatches.length){
                            self.hasBatches = true;
							item.batches = item.transactionProductBatches;
						}
                        

                    });
                }
            },

            fn_calculateAddOnsSummary: function () {

                let transactionProducts = JSON.parse(JSON.stringify(this.displayData.transactionProducts));

                let self = this;

                self.gstAddOns = [];
                self.addOnsSummary = [];

                transactionProducts.forEach(function (item) {
                    
                    if(item.transactionProductAddOns){

                        item.transactionProductAddOns.forEach(function (addOnsItem) {

                            self.fn_checkItemExist(addOnsItem, function (itemIndex) {

                                if(itemIndex==-1){
                                    if(addOnsItem.isGst){
                                        self.gstAddOns.push(addOnsItem);
                                    }
                                    else{
                                        self.addOnsSummary.push(addOnsItem);
                                    }
                                }
                                else{
                                    if(addOnsItem.isGst){
                                        let addOnsTotalValue = self.gstAddOns[itemIndex].value + addOnsItem.value;
                                        self.gstAddOns[itemIndex].value = self.roundUpto2Decimals(addOnsTotalValue);
                                        self.$forceUpdate();
                                    }
                                    else{
                                        let addOnsTotalValue = self.addOnsSummary[itemIndex].value + addOnsItem.value;
                                        self.addOnsSummary[itemIndex].value = self.roundUpto2Decimals(addOnsTotalValue);
                                        self.$forceUpdate();
                                    }
                                }

                            })

                            console.log(self.addOnsSummary);

                        })

                    }
                    
                })          

            },

            fn_checkItemExist: function (addOnsItem, callback) {
                let itemIndex = null;

                if(addOnsItem.isGst){
                    itemIndex = this.gstAddOns.findIndex(item=>{
                        return item.addOnsId == addOnsItem.addOnsId;
                    });
                }
                else{
                    itemIndex = this.addOnsSummary.findIndex(item=>{
                        return item.addOnsId == addOnsItem.addOnsId;
                    });
                }
                

                callback(itemIndex);
            },

            fn_bindCustomFields: function () {
                // let sections = JSON.parse(JSON.stringify(this.displayData.transactionSections));
                let sec = JSON.parse(JSON.stringify(this.displayData.transactionSections));
                let sections = sec.sort(function(a, b){return a.orderNumber - b.orderNumber});
                let fields = JSON.parse(JSON.stringify(this.displayData.transactionFields));

                this.customFields = [];

                let self = this;
                sections.forEach(function (sectionItem) {
                    sectionItem.items = [];
                    fields.forEach(function (item) {

                        if(item.sectionId == sectionItem.sectionId){
                            sectionItem.items.push(item);
                        }
                    })
                    self.customFields.push(sectionItem);
                })

                console.log(JSON.stringify(self.customFields));
            },

            fn_itemsSummary: function () {
                let self = this;
                self.itemsQuanity = [];

                this.displayData.transactionProducts.forEach(function (item) {

                    let taxpercent = 0;
                    let taxvalue = 0;
                    item.transactionProductAddOns.forEach(function (addOnsItem) {
                        if(addOnsItem.isGst == 1){
                            taxpercent += addOnsItem.percentage;
                            taxvalue += addOnsItem.value;
                        }
                        else if(addOnsItem.isGst == 2){
                            item.cesspercent = addOnsItem.percentage;
                            item.cessvalue = addOnsItem.value;
                        }
                    });

                    item.taxpercent = taxpercent;
                    item.taxvalue = taxvalue;

                    item.productVariants.forEach(function (variantItem) {

                        let qty = variantItem.qty;

                        let itemIndex = self.itemsQuanity.findIndex(existingItem=>{
                            return existingItem.unitId == variantItem.unitId && existingItem.packQuantity == variantItem.packQuantity;
                        });

                        if(itemIndex!=-1){
                            self.itemsQuanity[itemIndex].qty += qty;
                        }
                        else{
                            let vObj = {};
                            vObj.unitId = variantItem.unitId;
                            vObj.unitName = variantItem.unitName;
                            vObj.packQuantity = variantItem.packQuantity;
                            vObj.qty = variantItem.qty;
                            self.itemsQuanity.push(vObj);
                        }
                    })
                })
            },

            fn_bindInvoiceTemplate: function(type){

                if(this.disableAction){
                    return;
                }

                this.disableAction = true;

                let self = this;
                setTimeout(function(){
                    self.disableAction = false;
                },500);

                this.printTemplate = null;
                this.printData = null;

                // this.printTemplate = require('../../../invoice_templates/invoices/template'+this.displayData.invoiceTemplate).default;
                console.log(this.printTemplate);

                if(type=='sharewhatsapp'){
                    this.fn_shareInvoice('sharewhatsapp');
                }
                else if(type=='print'){
                    this.fn_getPrintData('InvoiceData', this.displayData.transactionId, 'print');
                }
                else if(type=='pdf'){
                    this.fn_getPrintData('InvoiceData', this.displayData.transactionId, 'pdf');
                }
                else if(type=='share'){
                    this.fn_shareInvoice('share');
                }

                // if(this.displayData.invoiceTemplate == 1)
                //  this.printTemplate = require('../../../invoice_templates/invoices/template1');
                // else if(this.displayData.invoiceTemplate == 2)
                //  this.printTemplate = printTemplate2;
                // else if(this.displayData.invoiceTemplate == 3)
                //  this.printTemplate = printTemplate3;
                // else if(this.displayData.invoiceTemplate == 4)
                //  this.printTemplate = printTemplate4;
                // else if(this.displayData.invoiceTemplate == 5)
                //  this.printTemplate = printTemplate5;
            },

            fn_checkTransactionAddOns: function(){
                for(let i=0; i<this.displayData.transactionAddOns.length; i++){
                    if(this.displayData.transactionAddOns[i].addOnsType == 1){
                        this.isDiscountAddons = true;
                    }
                    else if(this.displayData.transactionAddOns[i].addOnsType == 2){
                        this.isTaxAddons = true;
                    }
                    else if(this.displayData.transactionAddOns[i].addOnsType == 3){
                        this.isOtherChargesAddons = true;
                    }
                }
            },

            fn_getBottomMenu: function (tagId) {

                var bottomMenu = {"BottomMenu": [menuItem.cancelTransaction, menuItem.delete]};

                this.settings.transactionTypeId == transactionTypes.salesInvoice.id ? bottomMenu.BottomMenu.unshift(menuItem.createReceipt) : '';

                this.displayData.status==0 ? bottomMenu = {"BottomMenu": [menuItem.delete]} : '';

                if(this.$route.query && this.$route.query.pendingOrder){
                    bottomMenu.BottomMenu.unshift(menuItem.createInvoice);
                }

                this.setBottomMenu(tagId, bottomMenu, 
                    {
                        onSuccess: function (response) {
                            console.log(response);
                        },
                        onError: function (error) {
                            console.log(error);
                        }
                    }
                )
            },

            fn_menuItemClick: function(tagId, menuId, menuAction){
                // tagId id form future use
                // menuId id form future use
                if(menuAction=='edit' && this.displayData && this.displayData.transactionId){
                    this.fn_edit();
                }
                else if(menuAction=='cancel'){
                    this.actionType = 'cancel';
                    this.confirmationDialogHeader = "Cancel Transaction?";
                    this.confirmationMessage = "Cancelled transaction cannot be uncancelled. Are you sure you want to cancel this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='delete'){
                    this.actionType = 'delete';
                    this.confirmationDialogHeader = "Delete Transaction?";
                    this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                    this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
                }
                else if(menuAction=='createinvoice'){
                    this.$router.push({
                        path:'/transactions/sales/saleinvoice/items/new', 
                        query:{
                            tagId:this.guidWeb(), 
                            loadItems:{
                                transactionId:this.displayData.transactionId,
                                type:'orders',
                                partyId:this.displayData.partyId,
                                partyName:this.displayData.partyName,
                            }
                        }
                    });
                }
                else if(menuAction=='share'){
                    this.fn_bindInvoiceTemplate('share');
                }
                else if(menuAction=='createReceipt'){
                    this.$router.push({path:'/transactions/receipt/items/new', query:{tagId:this.guidWeb(), id:this.displayData.partyId, name:this.displayData.partyName, dt:1}});
                }
                else if(menuAction=='preview'){
                    this.fn_previewInvoice(true, 'sales');
                }
            },

            fn_edit: function () {
                if(this.hasBatches){
                    this.actionMethod = this.fn_closeDialogConfirmation;
                    this.$refs['ref_dialogConfirmation'].dialog_confimation = true;
                    return;
                }
                this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.displayData.transactionId+'/edit');
            },

            fn_closeDialogConfirmation: function(){
                this.$refs['ref_dialogConfirmation'].dialog_confimation = false;
            },

            fn_cancel: function(){
                this.actionType = 'cancel';
                this.confirmationDialogHeader = "Cancel Transaction?";
                this.confirmationMessage = "Cancelled transaction cannot be uncancelled. Are you sure you want to cancel this transaction?"
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_delete: function(){
                this.actionType = 'delete';
                this.confirmationDialogHeader = "Delete Transaction?";
                this.confirmationMessage = "Deleted transaction cannot be restored. Are you sure you want to delete this transaction?"
                this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = true;
            },

            fn_copy: function(){
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.displayData.transactionId+'/edit', query:{copy:1}});
            },

            fn_closeDialog: function(){
                this.$refs['ref_dialogPermission'].dialog_permission = false;
            },

            fn_showWebView: function () {
                var viewObject = {};

                var tagId = null;
                this.$route.params && this.$route.params.tagId ? tagId = this.$route.params.tagId : '';
                var self = this;

                this.showWebView(tagId, viewObject, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }, 
                        // onChange: null, 
                        onMenuItemClick: function (tagId, menuId, menuAction, itemId) {
                            self.fn_menuItemClick(tagId, menuId, menuAction, itemId)
                        }
                    }
                );
            },
        },
        mounted: function(){

            // window.js_globals.fn_getBottomMenu = this.fn_getBottomMenu;
            // window.js_globals.fn_onBackPress = this.fn_goBack;

            // this.fn_showWebView();
            
            // if(this.$route.params && this.$route.params.tagId){
            //     this.fn_get(this.$route.params.tagId);
            // }

            this.$eventHub.$on('delete', () => {
                this.fn_delete();
            });

            this.$eventHub.$on('cancel', () => {
                this.fn_cancel();
            });

            this.$eventHub.$on('edit', () => {
                this.fn_edit();
            });
            
            this.$eventHub.$on('preview', () => {
                this.fn_preview();
            });
        },
        beforeRouteEnter(to, from, next){
            next(vm => {
                vm.fn_initializeSettings();
                if(to.params && to.params.tagId){
                    vm.fn_get(to.params.tagId);
                }

                let isCalled = false;

                vm.$eventHub.$once('connected', () => {
                    setTimeout(() => {
                        if(vm.$route.params && vm.$route.params.tagId && !isCalled){
                            isCalled = true;
                            vm.fn_get(vm.$route.params.tagId);
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });
            });
        },
        beforeRouteUpdate(to, from, next){
            this.$eventHub.$off('connected');

            this.fn_initializeSettings();
            if(to.params && to.params.tagId){
                this.fn_get(to.params.tagId);
            }

            let isCalled = false;

            this.$eventHub.$once('connected', () => {
                setTimeout(() => {
                    if(this.$route.params && this.$route.params.tagId && !isCalled){
                        isCalled = true;
                        this.fn_get(this.$route.params.tagId);
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);
                    
                }, 1000);
            });
            next(true);
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('cancel');
            this.$eventHub.$off('delete');
            this.$eventHub.$off('edit');
            this.$eventHub.$off('preview');
            this.$eventHub.$off('connected');
            next(true)
        },
        computed:{
            discountAddons: function(){
                return this.displayData.transactionAddOns.filter(item=>{
                    return item.addOnsType == 1;
                });
            },
            taxAddons: function(){
                return this.displayData.transactionAddOns.filter(item=>{
                    return item.addOnsType == 2;
                });
            },
            otherChargesAddons: function(){
                return this.displayData.transactionAddOns.filter(item=>{
                    return item.addOnsType == 3;
                });
            },
            totalQty: function(){
                let totalQty = 0;
                if(this.itemsQuanity){
                    for(let i=0; i<this.itemsQuanity.length; i++){
                        totalQty += this.itemsQuanity[i].qty;
                    }   
                }
                return totalQty;
            },
            roundOffValue: function(){
                if(this.displayData && this.displayData.roundOffValue){
                    return this.displayData.roundOffValue;
                }
                else{
                    return this.roundUpto2Decimals(this.displayData.roundedAmount - this.displayData.amount);
                }
            },
            filteredItems: function () {

                let self = this;

                let groups = {
                    order: [],
                    challan: [],
                    invoice: [],
                    self: [],
                }

                let objSelf = {};
                objSelf.header = "Items";
                objSelf.baseTransactionNumber = self.displayData.mergeTransactionNumber;
                objSelf.baseTransactionId = null;
                objSelf.items = [];

                if(this.displayData.transactionProducts && this.displayData.transactionProducts.length){

                    this.displayData.transactionProducts.forEach(function (itemData) {
                        if(itemData.baseTransactionListId == self.settings.transactionTypeId && itemData.baseTransactionNumber == self.displayData.mergeTransactionNumber){
                            objSelf.items.push(itemData);
                        }
                    });

                    // sorting items as per order number
                    objSelf.items.sort(function(a, b){
                        return a.orderNumber - b.orderNumber;
                    });

                    groups.self.splice(0, 1, objSelf);

                    if(this.displayData.transactionListId == transactionTypes.salesInvoice.id || this.displayData.transactionListId == transactionTypes.deliveryChallan.id){

                        self.pendingOrders.forEach(function (item) {

                            let objOrder = {};
                            objOrder.items = [];

                            objOrder.header = "Order";
                            objOrder.baseTransactionNumber = item.baseTransactionNumber;
                            objOrder.baseTransactionId = item.transactionId;

                            objOrder.items = self.displayData.transactionProducts.filter(itemData=>{
                                return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.order.id;
                            });

                            // sorting order items as per order number
                            objOrder.items.sort(function(a, b){
                                return a.orderNumber - b.orderNumber;
                            });
                            
                            groups.order.push(objOrder);
                        });

                        if(this.displayData.transactionListId == transactionTypes.salesInvoice.id){

                            self.pendingChallans.forEach(function (item) {

                                let objChallan = {};
                                objChallan.items = [];

                                objChallan.header = "Delivery Challan";
                                objChallan.baseTransactionNumber = item.baseTransactionNumber;
                                objChallan.baseTransactionId = item.transactionId;

                                objChallan.items = self.displayData.transactionProducts.filter(itemData=>{
                                    return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.deliveryChallan.id;
                                });

                                // sorting challan items as per order number
                                objChallan.items.sort(function(a, b){
                                    return a.orderNumber - b.orderNumber;
                                });
                                
                                groups.challan.push(objChallan);
                            });

                        }
                    }

                    else if(this.displayData.transactionListId == transactionTypes.salesReturn.id){

                        self.pendingInvoices.forEach(function (item) {

                            let objInvoice = {};
                            objInvoice.items = [];

                            objInvoice.header = "Sale Invoice";
                            objInvoice.baseTransactionNumber = item.baseTransactionNumber;
                            objInvoice.baseTransactionId = item.transactionId;

                            objInvoice.items = self.displayData.transactionProducts.filter(itemData=>{
                                return item.baseTransactionNumber == itemData.baseTransactionNumber && itemData.baseTransactionListId == transactionTypes.salesInvoice.id;
                            });

                            // sorting invoice items as per order number
                            objInvoice.items.sort(function(a, b){
                                return a.orderNumber - b.orderNumber;
                            });
                            
                            groups.invoice.push(objInvoice);
                        });

                    }
                }

                for(let key in groups){

                    groups[key].sort(function(a, b){
                        return a.baseTransactionNumber - b.baseTransactionNumber;
                    });

                }
                
                console.log(JSON.stringify(groups));

                return groups;

            }
        },
        // beforeRouteLeave (to, from, next) {
        //     if(this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation || this.$refs['ref_dialogPermission'].dialog_permission){
        //         this.$refs['ref_dialogCancelDeleteTransaction'].dialog_confimation = false;
        //         this.$refs['ref_dialogPermission'].dialog_permission = false;
        //         next(false);
        //     }
        //     else{
        //         next(true); 
        //     }
        // }
    }

</script>